import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Link } from "react-router-dom";
import { top_menu_data } from "~/components/const/top_menu_data";

import { GetInfoList } from "~slices/InfoManagement";

import UserInfo from "~/components/block/UserInfo";

const Top = () => {
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);
    React.useEffect(() => {
        dispatch(GetInfoList({ topdata: true }));
    }, []);

    return (
        <React.Fragment>
            <Information />
            <UserInfo user_info={InformationState?.user_info} />
            <Menu />

            <Paper variant="outlined" square />
        </React.Fragment>
    );
};

const Information = () => {
    const InfoManagementState = useSelector((state) => state.InfoManagement);

    const information_data = InfoManagementState.info_list;

    const [open, set_open] = React.useState(false);
    return (
        <Box
            sx={{
                display: "flex",
            }}
        >
            <Paper
                sx={{
                    width: "100%",
                    minHeight: "200px",
                    backgroundColor: "#CCC",
                }}
                variant="outlined"
            >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, background: "#e5e0e0", p: "0.5rem" }}>
                    インフォメーション
                </Typography>
                <Box sx={{ m: "0.3rem" }}>
                    {information_data.map((info, i) => (
                        <Box key={i} sx={{ background: "#e8e8e8", m: 1, p: 1, borderRadius: 1 }}>
                            <Box>{info.release_date}</Box>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Box sx={{ ml: "2rem" }} onClick={() => set_open(open === i ? false : i)}>
                                    {info.title}
                                </Box>
                                {info?.content ? (
                                    <Button onClick={() => set_open(open === i ? false : i)}>詳細</Button>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            {open === i ? (
                                <Box sx={{ ml: "2rem", background: "#fff", p: 1 }}>
                                    <div
                                        className="html-preview"
                                        dangerouslySetInnerHTML={{
                                            __html: info?.content?.replace(/\n/g, "<br>"),
                                        }}
                                    ></div>
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                    ))}
                </Box>
            </Paper>
        </Box>
    );
};

// const UserInfo = () => {
//     const InformationState = useSelector((state) => state.Information);

//     console.log("████████Information█████████████████████████████");
//     console.log(InformationState);

//     const user_info = InformationState?.user_info;
//     const first_data = InformationState?.user_info[0] ? user_info[0] : {};

//     const view_list = [
//         {
//             label: "メールアドレス",
//             value: first_data.Address,
//         },
//         {
//             label: "表示名",
//             value: first_data.DisplayName,
//         },
//     ];

//     const affiliation_list = [
//         {
//             label: "会社",
//             value: "Company",
//         },
//         {
//             label: "所属",
//             value: "Department",
//         },
//     ];

//     return (
//         <Box
//             sx={{
//                 display: "flex",
//             }}
//         >
//             {user_info ? (
//                 <Paper
//                     sx={{
//                         width: "100%",
//                         // height: "150px",
//                         mt: "1rem",
//                     }}
//                     variant="outlined"
//                 >
//                     <Typography variant="h6" component="div" sx={{ flexGrow: 1, background: "orange", p: "0.5rem" }}>
//                         ユーザー情報
//                     </Typography>
//                     <Box sx={{ m: "0.3rem" }}>
//                         {view_list.map((val, i) => (
//                             <React.Fragment key={i}>
//                                 <Grid container direction="row" justifyContent="flex-start" alignItems="center">
//                                     <Box sx={{ width: "10rem" }}>{val.label}</Box>
//                                     <Box>{val.value}</Box>
//                                 </Grid>
//                             </React.Fragment>
//                         ))}

//                         {user_info?.length &&
//                             user_info.map((value, index) => (
//                                 <React.Fragment key={index}>
//                                     <Box sx={{ m: "0.3rem", background: "#faa", p: 1, borderRadius: 1 }}>
//                                         {affiliation_list.map((val, i) => (
//                                             <React.Fragment key={i}>
//                                                 <Grid
//                                                     container
//                                                     direction="row"
//                                                     justifyContent="flex-start"
//                                                     alignItems="center"
//                                                 >
//                                                     <Box sx={{ width: "10rem" }}>{val.label}</Box>
//                                                     <Box>{user_info[index][val.value]}</Box>
//                                                 </Grid>
//                                             </React.Fragment>
//                                         ))}
//                                     </Box>
//                                 </React.Fragment>
//                             ))}
//                     </Box>
//                 </Paper>
//             ) : (
//                 ""
//             )}
//         </Box>
//     );
// };

const Menu = () => {
    const menu_data = top_menu_data;
    const box_style = {
        width: "420px",
        height: "200px",
        margin: "1rem",
        display: "inline-block",
    };
    return (
        <Box sx={{ mt: "1rem", display: "block" }}>
            {menu_data.map((data, i) => (
                <Card sx={box_style} key={i}>
                    <CardHeader title={data.menu_title} sx={{ background: "#e5e0e0" }} />
                    <CardContent>
                        {data.menu_item.map((item, j) => (
                            <Typography variant="body2" color="text.secondary" key={j}>
                                <Link to={item.link}>{item.request}</Link>
                            </Typography>
                        ))}
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};
export default Top;
