import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    cost_trend_list: [],
};
export const SerchCostTrend = (arg) => {
    return AsyncFunction({
        type_name: "CostTrend/ユーザー情報の取得",
        method: "post",
        url: "/api/SerchCostTrend",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "CostTrend",
    initialState,
    reducers: {
        CostTrend_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("CostTrend/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.result != "OK") return;
                if (payload?.cost_trend_list) {
                    state.cost_trend_list = payload?.cost_trend_list.reverse();
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { CostTrend_set } = slice.actions;
