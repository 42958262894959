

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    mobile_list: [],

};

export const GetMobileList = (arg) => {
    return AsyncFunction({
        type_name: "MobileManagement/回線一覧取得",
        method: "post",
        url: "/api/GetMobileList",
        params: arg,
    });
};
// export const GetInfo = (arg) => {
//     return AsyncFunction({
//         type_name: "Info/お知らせ取得",
//         method: "get",
//         url: "/api/GetInfo",
//         params: arg,
//     });
// };


// Sliceを生成する
const slice = createSlice({
    name: "MobileManagement",
    initialState,
    reducers: {
        MobileManagement_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("MobileManagement") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.mobile_list) {
                    state.mobile_list = payload?.mobile_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { MobileManagement_set } = slice.actions;
