import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    mobile_data: [],
    user_data: [],
};
export const GetInfoList = (arg) => {
    return AsyncFunction({
        type_name: "RegistrationInfoDisplay/登録情報の取得",
        method: "get",
        url: "/api/GetRegistrationInfoDisplay",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "RegistrationInfoDisplay",
    initialState,
    reducers: {
        RegistrationInfoDisplay_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("RegistrationInfoDisplay") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.mobile_data) {
                    state.mobile_data = payload?.mobile_data;
                }
                if (payload?.user_data) {
                    state.user_data = payload?.user_data;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { RegistrationInfoDisplay_set } = slice.actions;
