import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostUserChange, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { useHistory } from "react-router";

import { addDays, addMonths, format } from "date-fns";
import { SelectApprover } from "~/components/block/SelectApprover";

const UserChange = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);

    const [change_cost_code_flag, setChangeCostCodeFlag] = React.useState(false);
    const [selected_user, setSelectedUser] = React.useState(false);

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        const model = elem?.model?.value;



        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }


        if (!selected_user) {
            alert("変更ユーザーを登録してください。");
            return;
        }

        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        dispatch(
            PostUserChange({
                tel: ApplicationState.selected_tel,
                remarks: elem?.remarks?.value,
                change_cost_code_flag: change_cost_code_flag,
                cost_code: elem?.cost_code.value,
                // account_code: elem?.account_code.value,
                hope_date: elem?.hope_date?.value + '-01',
                change_user_flag: true,
                change_name: selected_user?.DisplayName,
                change_email: selected_user?.Address,
                change_company: selected_user?.Company,
                change_department: selected_user?.Department,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });

    };

    const HandleSelectUser = (select_user) => {

        setSelectedUser(select_user);
    };

    // const [disabledCheck, setDisabledCheck] = React.useState(false);

    const after_month = (arg) => {
        // const day = addMonths(new Date(), 1);

        const day = new Date();
        const format_day = format(day, "yyyy-MM");
        return format_day;
    };

    return (
        <React.Fragment>
            <Typography variant="h4"> 使用者変更</Typography>
            携帯電話・PHSなどの使用者を変更する際の申請です
            <Typography variant="h5" sx={{ mt: 2 }}>
                ①使用者変更対象回線
            </Typography>
            <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                <SerchModelData />
            </Box>
            <Typography variant="h5" sx={{ mt: 2 }}>
                ②変更後の情報
            </Typography>
            <form onSubmit={HandleSubmit}>
                <Box sx={{ background: "#ececec", p: 2 }}>
                    <Box sx={{ mt: 1, mb: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="month"
                            label="利用開始希望月"
                            defaultValue={after_month()}
                            name="hope_date"
                        />
                    </Box>

                    {selected_user != false ? (
                        <>
                            <Card
                                variant="outlined"
                                sx={{
                                    background: "#c7c7c7",
                                    m: 2,
                                    p: 2,
                                    borderTop: "1px solid black",
                                    borderBottom: "1px solid black",
                                }}
                            >
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            メールアドレス
                                        </Grid>
                                        <Grid item xs>
                                            {selected_user?.Address}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            名前
                                        </Grid>
                                        <Grid item xs>
                                            {selected_user?.DisplayName}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            所属
                                        </Grid>
                                        <Grid item xs>
                                            {selected_user?.Company} <br />
                                            {selected_user?.Department}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => setSelectedUser("")}>変更</Button>
                                </CardActions>
                            </Card>
                        </>
                    ) : (
                        <SelectUser onSet={HandleSelectUser} />
                    )}

                    <FormControlLabel
                        control={
                            <Switch
                                checked={change_cost_code_flag}
                                onChange={(e) => setChangeCostCodeFlag(e.target.checked)}
                            />
                        }
                        label="経費負担を変更する"
                    />
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="経費負担コード"
                            name="cost_code"

                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                    {/* <Box sx={{ mt: 2, mb: 1 }}> */}
                    {/* <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="勘定コード"
                            disabled={!change_cost_code_flag}
                            name="account_code"

                        // helperText="※ 名前は部分一致で検索します。"
                        /> */}
                    {/* </Box> */}

                    <Box sx={{ mt: 1 }}>
                        申請理由/特記事項
                        <TextField
                            InputLabelProps={{ shrink: true, min: "2023-11-25" }}
                            type="text"
                            label=""
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                </Box>

                <Typography variant="h5">承認者</Typography>
                <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                    <SelectApprover />
                </Box>
                <Box
                    sx={{
                        pt: "1rem",
                    }}
                >
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

export default UserChange;
