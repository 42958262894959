import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { GetNewMobileApplicationList, Application_set, PostReject, PostNewMobile } from "~slices/Application";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const InsertNewMobile = () => {
    return (
        <React.Fragment>
            <Typography variant="h4">回線情報新規登録</Typography>

            <SearchItemInput />
            <ListTable />
        </React.Fragment>
    )
}

const SearchItemInput = () => {
    const dispatch = useDispatch();

    const InformationState = useSelector((state) => state.Information);

    const [applicant_email, setApplicantEmail] = React.useState(InformationState?.user_info[0]?.Address);
    const ApplicationState = useSelector((state) => state.Application);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        // const email = elem?.email?.value;

        const search_item = {
            ...ApplicationState.search_item,
            applicant_email: applicant_email,
        };

        dispatch(Application_set(["search_item", search_item]));
        dispatch(GetNewMobileApplicationList(search_item));
    };

    return (
        <React.Fragment>
            <form onSubmit={HandleSubmit}>
                <Box
                    sx={{
                        width: "100%",
                        mt: "1rem",
                        display: "grid",
                    }}
                >
                    <Box sx={{ mb: 1 }}>
                        <TextField
                            sx={{ width: "20rem" }}
                            value={applicant_email}
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="申請者メールアドレス"
                            name="applicant_email"
                            onChange={(e) => setApplicantEmail(e.target.value)}
                        />{" "}
                    </Box>

                    <Button variant="contained" color="primary" type="submit">
                        検索
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

const ListTable = (props) => {
    const ApplicationState = useSelector((state) => state.Application);
    const dispatch = useDispatch();
    const [selected_application, setSelecteApplication] = React.useState(false);
    const [tel_hyphen, setTelHyphen] = React.useState("");
    const HandleClick = (row) => {
        setSelecteApplication(row);
    };

    const popup_columns = [
        { headerName: "申請ID", field: "application_id" },
        { headerName: "申請者メールアドレス", field: "applicant_email" },
        { headerName: "申請者名", field: "applicant" },
        { headerName: "キャリア", field: "career" },
        { headerName: "使用者名", field: "user_name" },
        { headerName: "使用者メールアドレス", field: "user_email" },
        { headerName: "使用者部署名", field: "user_department" },
        { headerName: "使用者会社名", field: "user_company" },
        { headerName: "機種", field: "model" },
        { headerName: "申請理由/特記事項", field: "remarks" },
        { headerName: "用途", field: "usage" },
        { headerName: "使用者種別", field: "user_type" },
        { headerName: "経費負担コード", field: "cost_code" },
        { headerName: "勘定コード", field: "account_code" },
        { headerName: "申請日", field: "created_at" },
        { headerName: "承認者", field: "approver_name" },
        { headerName: "承認者メールアドレス", field: "approver_email" },
    ];

    const columns = [
        { headerName: "申請ID", field: "application_id", width: 130 },
        { headerName: "申請者メールアドレス", field: "applicant_email", width: 250 },
        { headerName: "申請者名", field: "applicant", width: 150 },
        { headerName: "キャリア", field: "career", width: 150 },
        { headerName: "使用者メールアドレス", field: "user_email", width: 250 },
        { headerName: "使用者名", field: "user_name", width: 150 },
        { headerName: "使用者部署名", field: "user_department" },
        { headerName: "使用者会社名", field: "user_company" },
        {
            headerName: "登録",

            field: "insert",
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => HandleClick(params.row)}>
                    回線登録
                </Button>
            ),
        },
    ];

    const handleNewMobile = (e) => {
        e.preventDefault();
        
        dispatch(
            PostNewMobile({
                application_id: selected_application.application_id,
                tel_hyphen: tel_hyphen,
                model: selected_application.model,
                user_email: selected_application.user_email,
                user_name: selected_application.user_name,
                division_name: selected_application.user_department,
                career: selected_application.career,
                company_name: selected_application.user_company,
                cost_code: selected_application.cost_code,
                created_at: selected_application.created_at,
            })
        ).then((data) => {
            setSelecteApplication(false);
            setTelHyphen("");
            dispatch(GetNewMobileApplicationList(ApplicationState.search_item));
        });
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <Dialog
                    open={selected_application == false ? false : true}
                    onClose={() => setSelecteApplication(false)}
                >
                    <form onSubmit={handleNewMobile}>
                        <DialogTitle sx={{ m: 0, p: 2, background: "deepskyblue" }}> 回線登録</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setSelecteApplication(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: "#454545",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box
                            sx={{
                                // minWidth: "40rem",
                                width: "100%",
                                mt: "1rem", // minWidth: "100%"
                                display: "grid",
                            }}
                        >
                            <Table>
                                <TableBody>
                                    {popup_columns.map((val, i) => (
                                        <React.Fragment key={i}>
                                            {selected_application[val.field] ? (
                                                <TableRow>
                                                    <TableCell>{val.headerName}</TableCell>
                                                    <TableCell> {selected_application[val.field]}</TableCell>
                                                </TableRow>
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box
                            sx={{
                                mt: "1rem",
                                p: 1,
                            }}
                        >
                            {/* <TextField
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="電話番号"
                                required
                                name="tel_hyphen"
                                // value={tel_hyphen}
                                inputProps={{
                                    maxLength: 4,
                                    pattern: "^[0-9]{3}$",
                                }}
                                // onChange={(e) => setTelHyphen(e.target.value)}
                                multiline
                                fullWidth
                                // helperText="※ハイフンを付けて入力してください"
                            /> */}
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="電話番号"
                                required
                                onChange={(e) => setTelHyphen(e.target.value)}
                                value={tel_hyphen}
                                inputProps={{
                                    maxLength: 20,
                                    pattern: "^[0-9]{3}-?[0-9]{4}-?[0-9]{4}$",
                                }}
                                size="small"
                                fullWidth
                                name="tel_hyphen"
                                sx={{ mt: "1rem" }}
                            />
                        </Box>
                        <DialogActions>
                            <Button variant="contained" type="submit">
                                登録
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>

            <Box
                sx={{
                    minHeight: 900,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                    display: "grid",
                }}
            >
                <DataGrid
                    rows={ApplicationState.application_list}
                    columns={columns}
                    disableCellSelectionOnClick
                    disableSelectionOnClick
                    pagination
                    // onRowClick={(e) => HandleRowClick(e.row)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </React.Fragment>
    );
};

export default InsertNewMobile;