import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    billing_list: [],
    lastPage: 1,
    current_page: 1,
    total: 0,
};
export const SerchBillingStatus = (arg) => {
    return AsyncFunction({
        type_name: "BillingStatus/ユーザー情報の取得",
        method: "post",
        url: "/api/SerchBillingStatus",
        params: arg,
    });
};

export const DounLoadBillingStatus = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadBillingStatus/ユーザー情報のcsvダウンロード",
        method: "post",
        url: "/api/DounLoadBillingStatus",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "BillingStatus",
    initialState,
    reducers: {
        BillingStatus_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("BillingStatus/") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload.result != "OK") return;
                    if (payload?.billing_list) {
                        state.billing_list = payload?.billing_list;
                    }
                    if (payload?.lastPage) {
                        state.lastPage = payload?.lastPage;
                    }
                    if (payload?.current_page) {
                        state.current_page = payload?.current_page;
                    }
                    if (payload?.total) {
                        state.total = payload?.total;
                    }
                    if (payload?.total_amount) {
                        state.total_amount = payload?.total_amount;
                    }
                }
            )

            .addMatcher(
                (action) => action.type.match("DounLoadBillingStatus") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    //CSVデータ
                    const data = action.payload;

                    //ダウンロードするCSVファイル名を指定する
                    const filename = "download.csv";

                    //BOMを付与する（Excelでの文字化け対策）
                    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
                    //Blobでデータを作成する
                    const blob = new Blob([bom, data], { type: "text/csv" });

                    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                        //その他ブラウザ
                    } else {
                        //BlobからオブジェクトURLを作成する
                        const url = (window.URL || window.webkitURL).createObjectURL(blob);
                        //ダウンロード用にリンクを作成する
                        const download = document.createElement("a");
                        //リンク先に上記で生成したURLを指定する
                        download.href = url;
                        //download属性にファイル名を指定する
                        download.download = filename;
                        //作成したリンクをクリックしてダウンロードを実行する
                        download.click();
                        //createObjectURLで作成したオブジェクトURLを開放する
                        (window.URL || window.webkitURL).revokeObjectURL(url);
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { BillingStatus_set } = slice.actions;
