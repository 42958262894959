import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostLoss, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { addDays, addMonths, format } from "date-fns";

import { SelectApprover } from "~/components/block/SelectApprover";

const MasterWriting = () => {
    const dispatch = useDispatch();

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;
    };

    const today = () => {
        const day = new Date();
        const format_day = format(day, "yyyy-MM-dd");
        return format_day;
    };
    return (
        <React.Fragment>
            <Typography variant="h4">回線情報登録</Typography>
            承認後回線情報を登録する画面です。
            <Box
                sx={{
                    pt: "1rem",
                }}
            >
                <form onSubmit={HandleSubmit}>
                    <Button
                        // disabled={ApplicationState.selected_tel ? false : true}
                        variant="contained"
                        size="large"
                        sx={{ height: "2.5rem" }}
                        type="submit"
                    >
                        登録
                    </Button>
                </form>
            </Box>
        </React.Fragment>
    );
};

export default MasterWriting;
