import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    open_create_Info: false,
    info_data: {
        title: "",
        target: "すべて",
        release_date: new Date().toLocaleDateString("sv-SE"),
    },
    info_list: [],
    selected_info_data: {},
    open_info_detail: false,
};
export const GetInfoList = (arg) => {
    return AsyncFunction({
        type_name: "InfoManagement/お知らせ一覧取得",
        method: "get",
        url: "/api/GetInfoList",
        params: arg,
    });
};
// export const GetInfo = (arg) => {
//     return AsyncFunction({
//         type_name: "Info/お知らせ取得",
//         method: "get",
//         url: "/api/GetInfo",
//         params: arg,
//     });
// };

export const CreateInfo = (arg) => {
    return AsyncFunction({
        type_name: "InfoManagement/お知らせ投稿",
        method: "post",
        url: "/api/CreateInfo",
        params: arg,
    });
};

export const DeleteInfo = (arg) => {
    return AsyncFunction({
        type_name: "InfoManagement/お知らせ削除",
        method: "post",
        url: "/api/DeleteInfo",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "InfoManagement",
    initialState,
    reducers: {
        InfoManagement_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("InfoManagement") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.info_list) {
                    state.info_list = payload?.info_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { InfoManagement_set } = slice.actions;
