import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { Application_set, GetModelData } from "~slices/Application";

export const SerchModelData = () => {
    const dispatch = useDispatch();
    const [selected_tel, setSelectedTel] = React.useState("");

    const ApplicationState = useSelector((state) => state.Application);

    // ApplicationState.selected_tel

    const HandleSerchFromTel = (e) => {
        e.preventDefault();
        // const elem = e.target.elements;
        dispatch(Application_set(["selected_tel", selected_tel]));
        dispatch(
            GetModelData({
                tel: selected_tel,
            })
        );
    };

    return (
        <React.Fragment>
            <form onSubmit={HandleSerchFromTel}>
                電話番号
                <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ pattern: "^([0-9]{6,})$", title: "電話番号を半角で入力してください。" }}
                    value={selected_tel}
                    type="text"
                    label=""
                    name="tel"
                    size="small"
                    onChange={(e) => setSelectedTel(e.target.value)}
                    required
                />
                <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                    検索
                </Button>
            </form>

            {ApplicationState.model_data ? (
                <>
                    <Box
                        sx={{
                            background: "#ececec",
                            m: 2,
                            p: 2,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                電話番号
                            </Grid>
                            <Grid item xs>
                                {ApplicationState.model_data.tel}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                モデル
                            </Grid>
                            <Grid item xs>
                                {ApplicationState.model_data.model}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                プラン
                            </Grid>
                            <Grid item xs>
                                {ApplicationState.model_data.plan}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                ""
            )}

            {/* {ApplicationState.user_list ? ( */}
                <>
                    <Box
                        sx={{
                            background: "#ececec",
                            m: 2,
                            p: 2,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                        }}
                    >
                        <Grid container spacing={2} sx={{ background: "#fff", borderRadius: 1, mb: 1 }}>
                            <Grid item xs={3}>
                                名前
                            </Grid>
                            <Grid item xs>
                                {/* {ApplicationState.user_list[0].DisplayName} */}

                                {ApplicationState.model_data.user_name}
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        会社
                                    </Grid>
                                    <Grid item xs>
                                        {ApplicationState.model_data.company_name}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        部署
                                    </Grid>
                                    <Grid item xs>
                                        {ApplicationState.model_data.division_name}
                                    </Grid>
                                </Grid>
                        {/* {ApplicationState.user_list.map((user, i) => (
                            <React.Fragment key={i}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        会社
                                    </Grid>
                                    <Grid item xs>
                                        {user.Company}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        部署
                                    </Grid>
                                    <Grid item xs>
                                        {user.Department}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))} */}
                    </Box>
                </>
            {/* ) : (
                ""
            )} */}
        </React.Fragment>
    );
};
// export default NewApplication;
