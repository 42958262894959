import React, { Infoef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set } from "~slices/UploadCSV";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { InfoManagement_set, GetInfoList, CreateInfo, DeleteInfo } from "~slices/InfoManagement";

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
} from "@mui/material";

import Popup from "~/components/block/Popup";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const columns = [
    { field: "detail_item_name", headerName: "名前", flex: true },
    { field: "price", headerName: "価格" },
    { field: "article_field", headerName: "備考", flex: true },
];

const BillingDetailTable = (props) => {
    const { su_data = [] } = props;
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Box
                sx={{
                    minHeight: 900,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                    display: "grid",
                }}
            >
                <DataGrid
                    rows={su_data}
                    columns={columns}
                    disableCellSelectionOnClick
                    disableSelectionOnClick
                    pagination
                    // onRowClick={(e) => HandleRowClick(e.row)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </React.Fragment>
    );
};

export default BillingDetailTable;
