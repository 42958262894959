export const admin_menu_data = [
    {
        menu_title: "管理者用機能",
        menu_item: [
            {
                request: "インフォメーションに社員向けメッセージを登録したい",
                title: "インフォメーション登録",
                link: "/InfoManagement",
            },
            {
                request: "管理ユーザーを確認したい",
                title: "管理ユーザー管理",
                link: "/UserManagement",
            },
            {
                request: "各種設定を行う",
                title: "設定",
                link: "/Config",
            },
        ],
    },

    {
        menu_title: "マスタ管理",
        menu_item: [
            {
                request: "ファイルをアップロードしたい",
                title: "ファイルアップロード",
                link: "/UploadCSV",
            },

            // {
            //     request: "承認後の端末情報の登録がしたい。",
            //     title: "回線情報登録",
            //     link: "/MasterWriting",
            // },

            {
                request: "承認後の端末情報の登録がしたい。",
                title: "回線情報登録",
                link: "/InsertNewMobile",
            },
        ],
    },
];
