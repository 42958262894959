import { combineReducers } from "redux";
import { Store, configureStore } from "@reduxjs/toolkit";

import Progress from "./slices/Progress";
import ErrorHandler from "./slices/ErrorHandler";
import Information from "./slices/Information";

// import User from "./slices/User";
import Popup from "./slices/Popup";
import Chat from "./slices/Chat";
import UserManagement from "./slices/UserManagement";

import UserAffiliation from "./slices/UserAffiliation";
import InfoManagement from "./slices/InfoManagement";
import StaffStatus from "./slices/StaffStatus";

import IndividualUsageView from "./slices/IndividualUsageView";
import BillingStatus from "./slices/BillingStatus";
import CostTrend from "./slices/CostTrend";

import Contact from "./slices/Contact";

import RegistrationInfoDisplay from "./slices/RegistrationInfoDisplay";

import AllBillingGuidance from "./slices/AllBillingGuidance";
import MobileManagement from "./slices/MobileManagement";

import Application from "./slices/Application";
import UploadCSV from "./slices/UploadCSV";
const reducer = combineReducers({
    Progress: Progress,
    ErrorHandler: ErrorHandler,
    Information: Information,
    // User: User,
    CostTrend: CostTrend,
    Popup: Popup,
    UploadCSV: UploadCSV,
    UserManagement: UserManagement,
    UserAffiliation: UserAffiliation,
    InfoManagement: InfoManagement, //お知らせ
    StaffStatus: StaffStatus,
    IndividualUsageView: IndividualUsageView, //個人利用状況
    BillingStatus: BillingStatus,
    RegistrationInfoDisplay: RegistrationInfoDisplay,
    AllBillingGuidance: AllBillingGuidance,
    Application: Application,
    MobileManagement: MobileManagement,
    Contact: Contact,
});

const thunkArguments = {};
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: thunkArguments,
            },
        }),
});
thunkArguments.store = store;

export default store;
