import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    Label,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
} from "recharts";

import { SerchCostTrend, CostTrend_set } from "~slices/CostTrend";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";
import { useHistory } from "react-router";

const DeptAllocationList = () => {
    const dispatch = useDispatch();

    // const CostTrendState = useSelector((state) => state.CostTrend);

    const [month, setMonth] = React.useState("2023-10");

    const billing_amount_sum = 76382;
    // const HandleSubmit = (e) => {
    //     dispatch(SerchBillingStatus());
    //     // e.preventDefault();
    // };

    const SetCompany = (selected_data) => {
        console.log(selected_data.company);
        // dispatch(
        //     SerchCostTrend({
        //         month: month,
        //         company: selected_data.company,
        //         department: selected_data.department,
        //     })
        // );
    };

    const columns = [
        { label: "電話事業者請求関連合計 ※2", price: 100, tax: 0, nontax: 0 },
        { label: "通信費支払代行関連合計 ※3", price: 100, tax: 0, nontax: 0 },
        { label: "通信費小計", price: 100, tax: 0, nontax: 0 },
        { label: "機器関連合計", price: 100, tax: 0, nontax: 0 },
        { label: "業務委託関連合計", price: 100, tax: 0, nontax: 0 },
        { label: "通信費以外小計", price: 100, tax: 0, nontax: 0 },
        { label: "その他合計 ※4", price: 100, tax: 0, nontax: 0 },
        { label: "ご請求額合計", price: 100, tax: 0, nontax: 0, coler: "#FFCCCC" },
    ];

    return (
        <React.Fragment>
            <Typography variant="h4">部門振り分け一覧</Typography>
            通信費の部門振分け一覧です
            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    type="month"
                    label="請求月"
                    name="month"
                    size="small"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    // {...props}
                />
                <Box sx={{ mt: 1 }}>
                    <SelectUser mode="company" onSet={(new_list) => SetCompany(new_list)} buttonLabel="検索" />
                </Box>
                {/* <Button onClick={() => HandleSubmit()}>取得</Button> */}
            </Grid>
            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <Box sx={{ m: 2 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Box sx={{ p: 1, border: "solid 1px black", width: "150px" }}>ご請求額合計</Box>
                        <Box sx={{ p: 1, border: "solid 1px black", width: "150px", textAlign: "right" }}>
                            ¥{billing_amount_sum.toLocaleString()}
                        </Box>
                    </Grid>
                </Box>
                部門振分け一覧内訳
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: "#CCCCCC" }}>
                            <TableCell>部門別ご請求項目</TableCell>
                            <TableCell align="right">金額</TableCell>
                            <TableCell align="right">うち消費税</TableCell>
                            <TableCell align="right">うち非課税 ※1</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {columns.map((row) => (
                            <TableRow key={row.label} sx={{ background: row?.coler ? row.coler : "none" }}>
                                <TableCell>{row.label}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                <TableCell align="right">¥{row.tax}</TableCell>
                                <TableCell align="right">¥{row.nontax}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {/* <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell>合計</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">¥{tm_sum_call_fee}</TableCell>
                                </TableRow>
                            </TableBody> */}
                </Table>
            </Grid>
            <Bikou />
        </React.Fragment>
    );
};

const Bikou = () => {
    return (
        <Box sx={{ m: 1 }}>
            <table cellSpacing={0} cellPadding={0} border={0}>
                <tbody>
                    <tr>
                        <td className="bun12">
                            <>
                                ※1 「うち非課税」とは、国際電話他のご利用分です。
                                <br />
                                ※2 「その他」の詳細は、ヘルプデスクまでご確認ください。
                            </>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

export default DeptAllocationList;
