import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

import { PostContact } from "~slices/Contact";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";
import { useHistory } from "react-router";

const Contact = () => {
    const dispatch = useDispatch();

    const [num, setNum] = React.useState("");
    // const to_list = [
    //     { label: "栗田工業", email: "test@test2.jp" },
    //     { label: "栗田総合サービス", email: "test@test.jp" },
    // ];


    const to_list = [
        { label: '登録情報について', email: "kwi_kanrihonbu_GSD@kurita-water.com" },
        // { label: '解約端末の返却について', email: "kwi_helpdesk@kurita-water.com" },
        // { label: '情報端末の設定について', email: "kwi_helpdesk@kurita-water.com" },
        // { label: 'アプリの利用、設定について', email: "kwi_helpdesk@kurita-water.com" },
        { label: '納期について', email: "kwi_kanrihonbu_GSD@kurita-water.com" },
        // { label: 'モバイルWi-Fiの納期について', email: "kwi_kanrihonbu_GSD@kurita-water.com" },
        { label: 'その他', email: "kwi_kanrihonbu_GSD@kurita-water.com" },
    ];
    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        const params = {
            email: to_list[num]['email'],
            label: to_list[num]['label'],
            text: elem.text.value,
            tel: elem.tel.value,
        };
        dispatch(PostContact(params)).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
            }
        });
    };

    return (
        <>
            <Typography variant="h4">お問い合わせ</Typography>

            <Caution />
            <form onSubmit={HandleSubmit}>
                <Box sx={{ m: 1 }}>
                    <TextField
                        select
                        value={num}
                        label="お問い合わせカテゴリ"
                        onChange={(e) => setNum(e.target.value)}
                        sx={{ minWidth: "20rem", mr: 1 }}
                        required
                    >
                        {to_list &&
                            to_list.map((to, i) => (
                                <MenuItem key={i} value={i}>
                                    {to.label}
                                </MenuItem>
                            ))}
                    </TextField>

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        // inputProps={{
                        //     maxLength: 12,
                        //     pattern: "^[0-9]+$",
                        // }}
                        type="text"
                        label="内容"
                        name="text"
                        sx={{ mt: "1rem", mb: "1rem" }}
                        fullWidth
                        minRows={4}
                        multiline
                        required
                    />
                    <br />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        // inputProps={{
                        //     maxLength: 12,
                        //     pattern: "^[0-9]+$",
                        // }}
                        type="text"
                        label="連絡先電話番号"
                        name="tel"
                        sx={{ mt: "1rem", mb: "1rem" }}

                        required
                    />
                    <br />
                    ※申請内容に関するお問い合わせは「申請ID」を入れてください
                    <br />

                    <br />

                    <Button type="submit" variant="contained">
                        送信
                    </Button>
                </Box>
            </form>
        </>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "1px solid #FF6600", margin: "15px 0 15px 0px" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    お問い合わせについて
                </div>
                <div style={{ margin: "0 0 5px 5px", width: "80%" }}>
                    お問い合わせカテゴリを選択し、内容を記載し送信してください。<br />

                    <span id="lbl" style={{ color: "Red" }}>

                        <br />
                        iPhone本体の設定やアプリのインストールにつきましては<br />
                        以下メールアドレスまたは電話番号より<br />
                        「ヘルプデスク」へお問合せください。<br />
                        <br />
                        メールアドレス：kwi_helpdesk@kurita-water.com<br />
                        電話番号：03-6743-6660<br />

                    </span>
                    <div style={{ marginLeft: 20 }}>
                        <br />

                        <span id="Label1" style={{ color: "Red" }}></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Contact;
