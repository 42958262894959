import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";
import { useHistory } from "react-router";

import { PostModelChange, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { SelectApprover } from "~/components/block/SelectApprover";

const ModelChange = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);

    const [change_user_flag, setAnotherUser] = React.useState(false);
    const [selected_user, setSelectedUser] = React.useState({});

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        const model = elem?.model?.value;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }
        
        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        dispatch(
            PostModelChange({
                tel: ApplicationState.selected_tel,
                model: model,
                remarks: elem?.remarks?.value,
                change_user_flag: change_user_flag,
                change_name: selected_user?.DisplayName,
                change_email: selected_user?.Address,
                change_company: selected_user?.Company,
                change_department: selected_user?.Department,
                sending_name: elem?.sending_name?.value,
                sending_post_code: elem?.sending_post_code?.value,
                sending_address: elem?.sending_address?.value,
                sending_tel: elem?.sending_tel?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });
    };

    const HandleSelectUser = (select_user) => {
        setSelectedUser(select_user);

    
    };

    // const [disabledCheck, setDisabledCheck] = React.useState(false);

    return (
        <React.Fragment>
            <Typography variant="h4">機種変更申請</Typography>
            新しい機種に変更する際の申請です
            <Box sx={{ color: "red" }}>※故障時（画面破損・バッテリー劣化等）は 端末修理 より申請してください。</Box>
            <Box>変更機器に関する記入事項 → 費用と承認に関する記入事項 → ご記入事項確認 → 申請完了</Box>
            <Caution />
            <Caution02 />
            <div className="essential" style={{ margin: "10px 0 5px 10px" }}>
                <span className="kome">※</span> は、必須項目ですので、必ず記入してください
            </div>
            <Typography variant="h5">①現在ご利用機種</Typography>
            <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                <SerchModelData />
            </Box>
            <Box sx={{ textAlign: "center", fontSize: "2rem" }}>⇩</Box>
            <Typography variant="h5">②ご変更内容</Typography>
            <form onSubmit={HandleSubmit}>
                <Box sx={{ background: "#ececec", p: 2 }}>
                    <SelectModel />

                    <Box>
                        {/* <FormControlLabel
                            control={
                                <Switch checked={change_user_flag} onChange={(e) => setAnotherUser(e.target.checked)} />
                            }
                            label="利用者の変更"
                        /> */}
                        {change_user_flag ? (
                            <>
                                {selected_user.hasOwnProperty("Address") ? (
                                    <>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}>
                                                        メールアドレス
                                                    </Grid>
                                                    <Grid item xs>
                                                        {selected_user?.Address}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}>
                                                        名前
                                                    </Grid>
                                                    <Grid item xs>
                                                        {selected_user?.DisplayName}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}>
                                                        所属
                                                    </Grid>
                                                    <Grid item xs>
                                                        {selected_user?.Company} <br />
                                                        {selected_user?.Department}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <Button onClick={() => setSelectedUser("")}>変更</Button>
                                            </CardActions>
                                        </Card>
                                    </>
                                ) : (
                                    <SelectUser onSet={HandleSelectUser} />
                                )}
                            </>
                        ) : (
                            ""
                        )}
                        <br />
                        申請理由/特記事項
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label=""
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                            // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                </Box>

                <InputDestination titleNum="③" />
                <Typography variant="h5">承認者</Typography>
                <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                    <SelectApprover />
                </Box>
                <Box
                    sx={{
                        pt: "1rem",
                    }}
                >
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "1px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    機種変更する場合の留意点
                </div>
                <div style={{ margin: "0 20px 5px 5px", width: "80%" }}>
                    &lt; iPhoneへの切替時の留意事項&gt;
                    <br />
                    <span id="lbl" style={{ color: "Red" }}>
                        ・納品から一か月以内に新しい端末へ機種変更してください。切替えを実施しない場合は、納品日の翌月末に強制的に新しい端末に切替わります。
                    </span>
                </div>
                <div style={{ margin: "0 20px 5px 5px", width: "80%" }}>
                    &lt; GRATINA 返却時の注意事項&gt;
                    <br />
                    ・iPhoneへの回線切替後に送付してください。
                </div>
                <div style={{ margin: "0 20px 5px 20px", width: "75%" }}>
                    【GRATINAの返却先】
                    <br />
                    ------------------------------------------------------------
                    <br />
                    〒102-8460 東京都千代田区飯田橋3-10-10 ガーデンエアタワー6F
                    <br />
                    KDDI株式会社 モバイルサービス部 端末リサイクルセンター係
                    <br />
                    TEL: 03-6365-1171
                    <br />
                    ------------------------------------------------------------
                </div>
                <div style={{ margin: "0 20px 5px 5px", width: "80%" }}>
                    &lt; 機種変更後の古い端末の取り扱いについて &gt;
                    <br />
                    機種変更後、古い端末に必ず本申請受付メールを印刷した紙を巻き付けて、下記宛てに送ってください。
                </div>
                <div style={{ margin: "0 20px 5px 20px", width: "75%" }}>
                    &lt;&lt; 端末送付先&gt;&gt;
                    <br />
                    〈ヤマト便の場合〉
                    <br />
                    〒243-0303
                    <br />
                    027-600 厚木物流システム支店気付
                    <br />
                    KDDI株式会社 SL故障受付担当 係<br />
                    TEL: 046-408-7130
                    <br />
                    <br />
                    〈ヤマト便以外の場合〉
                    <br />
                    〒252-0146 神奈川県相模原市緑区大山町4-7
                    <br />
                    ロジポート橋本303区画
                    <br />
                    KDDI株式会社 東日本物流キッティング
                    <br />
                    センター故障受付担当 係<br />
                    TEL: 0800-0800125
                </div>
                <div style={{ margin: "0 20px 5px 5px", width: "80%" }}>
                    ※ 充電器も合わせて送付してください。
                    <br />※ 古い端末のデータは削除せず、電源を切った状態で送ってください。KDDIが初期化を実施します。
                </div>
                <div style={{ margin: "15px 20px 5px 5px", width: "80%" }}>
                    ※&nbsp;上記の送付先電話番号は、発送用の連絡先となる為、お問い合せの応対は出来かねます。
                    <br />
                    <br />
                    返却端末の発送に関するお問い合せは、下記の連絡先までご連絡ください。
                    <br />
                    情報システムヘルプデスク（03-6743-6660&nbsp;/&nbsp;kwi_helpdesk@kurita-water.com）
                </div>
            </div>
        </React.Fragment>
    );
};

const Caution02 = () => {
    return (
        <React.Fragment>
            <div style={{ border: "1px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    USBスティック及びWifiルーターの機種変更
                </div>
                <div style={{ margin: "0 20px 5px 5px", width: "80%" }}>
                    USBスティック及びWifiルーターは、機種変更ができません。
                    <br />
                    ※ 理由は機種変更時に必ず回線番号が変更になるためです。
                    <br />※
                    USBスティック及びWifiルーターを機種変更する際は、「新規申請」にて新しい端末を申請し、機器到着後に旧端末の「解約」申請をしてください。
                </div>
            </div>
        </React.Fragment>
    );
};

export default ModelChange;
