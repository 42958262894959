import React, { Infoef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set } from "~slices/UploadCSV";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { InfoManagement_set, GetInfoList, CreateInfo, DeleteInfo } from "~slices/InfoManagement";

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
} from "@mui/material";

import Popup from "~/components/block/Popup";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const ETCUseTable = () => {
    const dispatch = useDispatch();

    return <React.Fragment>ETCUseTable</React.Fragment>;
};

export default ETCUseTable;
