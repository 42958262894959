import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
} from "@mui/material";

import { SerchStaffStatus, StaffStatus_set } from "~slices/StaffStatus";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const DeptInfoStatus = () => {
    const dispatch = useDispatch();

    const HandleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Typography variant="h4">部門情報観覧</Typography>
        </React.Fragment>
    );
};

export default DeptInfoStatus;
