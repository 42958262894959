import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { Information_set, PostConfig } from "~slices/Information";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { addDays, addMonths, format } from "date-fns";

import { SelectApprover } from "~/components/block/SelectApprover";

const Config = () => {
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);

    const [config, setConfig] = React.useState(InformationState.config);

    const today = () => {
        const day = new Date();
        const format_day = format(day, "yyyy-MM-dd");
        return format_day;
    };

    const [start_day, setStartDay] = React.useState(today);

    React.useEffect(() => {
        // setConfig(InformationState.config);
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        dispatch(PostConfig({ config: config, start_day: start_day }));
    };

    const HandleChenge = (e) => {
        setConfig({ ...config, [e.target.name]: e.target.value });
    };
    return (
        <React.Fragment>
            <Typography variant="h4">設定</Typography>
            様々な設定を行う画面です。
            <Box
                sx={{
                    pt: "1rem",
                }}
            >
                <form onSubmit={HandleSubmit}>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            value={config?.commission}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="手数料"
                            name="commission"
                            onChange={HandleChenge}
                        />
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            value={start_day}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label="反映日"
                            name="start_day"
                            onChange={(e) => setStartDay(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Button
                            // disabled={ApplicationState.selected_tel ? false : true}
                            variant="contained"
                            size="large"
                            sx={{ height: "2.5rem" }}
                            type="submit"
                        >
                            登録
                        </Button>
                    </Box>
                </form>
            </Box>
        </React.Fragment>
    );
};

export default Config;
