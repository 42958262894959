import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { Application_set, GetModelData } from "~slices/Application";

export const InputDestination = (props) => {
    const { titleNum = "", title = "機器送付先" } = props;
    const dispatch = useDispatch();
    const [post_code_1, setPostCode1] = React.useState("");
    const [post_code_2, setPostCode2] = React.useState("");

    return (
        <React.Fragment>
            {" "}
            <Typography variant="h5" sx={{ mt: 2 }}>
                {titleNum}
                {title}
            </Typography>
            <Box sx={{ background: "#ececec", p: 2 }}>
                <Box sx={{ color: "red" }}>
                    <span>
                        {/* 【注意事項】
                        <br />
                        ・住所、送付先名称に半角入力不可です。全角で入力してください。（住所47文字以内、送付先名称30文字以内）
                        <br />
                        ・宛先名には社名・部署名・ご担当者様名をご入力ください。
                        <br />
                        ・自宅への配送は不可です。所属する事業所を入力してください。 */}
                        ※自宅への配送は不可です。
                    </span>
                </Box>
                <Grid container direction="column" justifyContent="center" alignItems="flex-start">

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="郵便番号"
                        value={`${post_code_1}-${post_code_2}`}
                        inputProps={
                            {
                                // maxLength: 8,
                                // pattern: "^[0-9]+$",
                            }
                        }
                        name="sending_post_code"
                        sx={{ mt: "1rem", display: "none" }}
                        required
                    />
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid sx={{ fontSize: "1rem", padding: "1rem 0.5rem 0rem 0rem" }}>〒</Grid>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="郵便番号"
                            required
                            value={post_code_1}
                            onChange={(e) => setPostCode1(e.target.value)}
                            inputProps={{
                                maxLength: 4,
                                pattern: "^[0-9]{3}$",
                            }}
                            size="small"
                            name="post_code_1"
                            sx={{ mt: "1rem" }}
                        />
                        <Grid sx={{ fontSize: "2rem", padding: "0.5rem 1rem 0rem 1rem" }}>-</Grid>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label=""
                            size="small"
                            value={post_code_2}
                            onChange={(e) => setPostCode2(e.target.value)}
                            inputProps={{
                                maxLength: 4,
                                pattern: "^[0-9]{4}$",
                            }}
                            name="post_code_2"
                            sx={{ mt: "1rem" }}
                            required
                        />
                    </Grid>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="住所(全角47文字以内)"
                        name="sending_address"
                        inputProps={{
                            maxLength: 47,
                        }}
                        sx={{ mt: "1rem" }}
                        fullWidth
                        required
                    />

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="会社名・部署名・ご担当者名(全角30文字以内)"
                        name="sending_name"
                        sx={{ mt: "1rem" }}
                        inputProps={{
                            maxLength: 30,
                        }}
                        fullWidth
                        required
                    />
                    
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            maxLength: 12,
                            pattern: "^[0-9]+$",
                        }}
                        type="text"
                        label="電話番号"
                        name="sending_tel"
                        sx={{ mt: "1rem" }}
                        required
                    />
                </Grid>
            </Box>
        </React.Fragment>
    );
};
// export default NewApplication;
