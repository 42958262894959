import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostLoss, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { addDays, addMonths, format } from "date-fns";
import { useHistory } from "react-router";

import { SelectApprover } from "~/components/block/SelectApprover";

const Loss = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);
    const [loss_type, setLossType] = React.useState("紛失");

    const loss_type_list = ["紛失", "盗難"];

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }
        
        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        dispatch(
            PostLoss({
                tel: ApplicationState.selected_tel,
                loss_type: loss_type,
                loss_detail: elem?.loss_detail?.value,
                sending_name: elem?.sending_name?.value,
                sending_post_code: elem?.sending_post_code?.value,
                sending_address: elem?.sending_address?.value,
                sending_tel: elem?.sending_tel?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
                apple_id: elem?.apple_id?.value,
                apple_password: elem?.apple_password?.value,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });
    };

    const today = () => {
        const day = new Date();
        const format_day = format(day, "yyyy-MM-dd");
        return format_day;
    };
    return (
        <React.Fragment>
            <Typography variant="h4">紛失連絡</Typography>
            携帯電話が紛失した際の申請です
            <Box
                sx={{
                    pt: "1rem",
                }}
            >
                <Caution />
                <Typography variant="h5">①現在ご利用機種</Typography>
                <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                    <SerchModelData />

                    <Box sx={{ mt: 1 }}>メールアドレス(AppleID)</Box>
                    <TextField InputLabelProps={{ shrink: true }} type="text" label="" name="apple_id" />

                    <Box sx={{ mt: 1 }}>パスワード(AppleID)</Box>
                    <TextField InputLabelProps={{ shrink: true }} type="text" label="" name="apple_password" />
                </Box>

                <form onSubmit={HandleSubmit}>
                    <Typography variant="h5">②紛失内容</Typography>
                    <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                        <FormControl sx={{ mb: "1rem" }}>
                            <InputLabel id="select-loss-label"> 紛失内容</InputLabel>
                            <Select
                                value={loss_type}
                                sx={{ width: "20rem" }}
                                fullWidth
                                // labelId="select-number_BottleKeep-label"
                                label=" 端末修理内容"
                                name="loss_type"
                                // value={Number(number_bottle_keep)}
                                onChange={(e) => setLossType(e.target.value)}
                            >
                                {loss_type_list?.map((loss_type, index) => (
                                    <MenuItem value={loss_type} key={index}>
                                        {loss_type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box sx={{ mt: 1 }}>発生日</Box>
                        <TextField
                            defaultValue={today()}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label=""
                            name="loss_date"
                        />

                        <Box sx={{ mt: 1 }}>状況</Box>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label=""
                            name="loss_detail"
                            multiline
                            rows={4}
                            fullWidth
                            helperText="（具体的状況をご記入ください）"
                        />
                    </Box>

                    <InputDestination titleNum="③" title="代替機送付先" />

                    <Typography variant="h5">承認者</Typography>
                    <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                        <SelectApprover />
                    </Box>

                    <Button
                        disabled={ApplicationState.selected_tel ? false : true}
                        variant="contained"
                        size="large"
                        sx={{ height: "2.5rem" }}
                        type="submit"
                    >
                        申請
                    </Button>
                </form>
            </Box>
        </React.Fragment>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "2px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    携帯電話を紛失した場合の手続きについて
                </div>
                <div style={{ margin: "5px 20px 10px 10px" }}>
                    １．紛失時は以下の通り対応してください。
                    <div style={{ marginLeft: 20 }}>
                        （１）紛失対応デスクに電話し、iPhoneのデータ削除依頼を実施してください。
                        <br />
                        <br />
                        &nbsp;&nbsp;&lt;紛失対応デスク&gt; ※２４時間３６５日受付
                        <br />
                        ・国内 : 0120-822-003
                        <br />
                        ・海外 : 045-330-5851 (+81453305851)
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;※データ削除処理には「利用者氏名」・「電話番号」が必要です。
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iPhoneの電話番号が不明な場合は、情報システムヘルプデスクへ問い合わせてください。
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;（TEL:03-6743-6660 平日8:00-22:00 / 土曜9:00-17:00）
                        <br />
                        <br />
                        （２）データが削除されたことを情報システムヘルプデスクに確認した後、KDDIのサポートデスクへ電話し、通信停止を実施してください。
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;※データが削除されていない場合は、通信停止しないでください。
                        <br />
                        【連絡先】 KDDIサポートデスク 0077-780-162 【受付時間】24時間365日
                    </div>
                </div>
                <div style={{ margin: "5px 20px 10px 10px" }}>
                    ２．代替機到着後に紛失機を発見しましたら、必ず本申請の受付メールを巻き付けて、下記宛てに送ってください。
                    <div style={{ marginLeft: 20 }}>
                        &lt;送付先&gt;
                        <br />
                        〈ヤマト運輸の場合〉
                        <br />
                        〒243-0303
                        <br />
                        027-600 厚木物流システム支店気付
                        <br />
                        KDDI株式会社 SL レンタルT 係<br />
                        <br />
                        〈ヤマト便以外の場合〉
                        <br />
                        〒252-0146 神奈川県相模原市緑区大山町4番7号
                        <br />
                        ロジポート橋本303区画
                        <br />
                        KDDI株式会社 東日本物流キッティングセンター SL レンタルT 係<br />
                    </div>
                </div>
                <div style={{ margin: "5px 20px 5px 10px" }}>
                    【補足】
                    <br />
                    ・予備機は無償手配です。
                    <br />
                    ※1回目の紛失より6か月以内に2回目の紛失をした場合は紛失損害金(50,000円)がかかります。
                    <br />
                    ・当日16時までに依頼のものは翌々営業日納品となります。
                    <br />
                    （北海道、九州、沖縄、山口、広島、島根は+1日かかります。）
                    <br />
                    ・レンタル端末のため、警察へ紛失届出を出していただき、受理番号を修理依頼書（※）へ記載の上、KDDIへメール連絡頂く必要があります。
                    <br />
                    ※依頼書のリンクは本申請の承認完了メールに記載いたしますので、ファイルをダウンロードし記入の上
                    <br />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Loss;
