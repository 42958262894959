import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";

import {
    Grid,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const CheckModal = (props) => {
    const {
        open = false,
        handleClose,
        title = "",
        children = "",
        dialogActions = "",

        onApplication,
        checkItem = [],
    } = props;

    return (
        <>
            <div>
                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle
                        sx={{ m: 0, p: 2, minHeight: "3rem", background: "#70cdff" }}
                        id="customized-dialog-title"
                    >
                        {title}
                        申請情報確認
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers sx={{ minWidth: "500px", minHeight: "300px", overflow: "hidden" }}>
                        {checkItem.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid container sx={{ borderBottom: "solid 1px gray", p: "1rem 1rem  0rem 1rem" }}>
                                    <Grid item xs={6}>
                                        {item.label}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {item.value}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))}
                        {children}
                    </DialogContent>
                    <DialogActions>
                        {dialogActions}

                        <Button variant="contained" onClick={onApplication}>
                            申請
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    );
};

export default CheckModal;
