import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    Paper,
} from "@mui/material";

import UserInfo from "~/components/block/UserInfo";

import { GetInfoList, RegistrationInfoDisplay_set } from "~slices/RegistrationInfoDisplay";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const RegistrationInfoDisplay = () => {
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.RegistrationInfoDisplay);

    const mobile_data = thisState.mobile_data;

    React.useEffect(() => {
        dispatch(GetInfoList());
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
    };

    const mobile_label_list = [
        { label: "機種", name: "model" },
        // { label: "プラン", name: "plan" },
        { label: "電話番号", name: "tel" },
    ];
    return (
        <React.Fragment>
            <Typography variant="h4">登録情報表示</Typography>
            <UserInfo user_info={thisState?.user_data} />

            <Paper
                sx={{
                    width: "100%",
                    // height: "150px",
                    mt: "1rem",
                }}
                variant="outlined"
            >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, background: "#01a3bb", p: "0.5rem" }}>
                    端末情報
                </Typography>
                <Box sx={{ m: "0.3rem" }}>
                    {mobile_data.map((data, i) => (
                        <React.Fragment key={i}>
                            <Box sx={{ background: "#7de3ff", p: 1, m: 1, borderRadius: 1 }}>
                                {mobile_label_list.map((val, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                            <Box sx={{ width: "10rem" }}>{val.label}</Box>
                                            <Box>{data[val?.name]}</Box>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
            </Paper>
        </React.Fragment>
    );
};

export default RegistrationInfoDisplay;
