import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Link } from "react-router-dom";
import { top_menu_data } from "~/components/const/top_menu_data";

const UserInfo = (props) => {
    const { user_info = [] } = props;

    const first_data = user_info[0] ? user_info[0] : {};

    const view_list = [
        {
            label: "メールアドレス",
            value: first_data.Address,
        },
        {
            label: "表示名",
            value: first_data.DisplayName,
        },
    ];

    const affiliation_list = [
        {
            label: "会社",
            value: "Company",
        },
        {
            label: "所属",
            value: "Department",
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
            }}
        >
            {user_info ? (
                <Paper
                    sx={{
                        width: "100%",
                        // height: "150px",
                        mt: "1rem",
                    }}
                    variant="outlined"
                >
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, background: "orange", p: "0.5rem" }}>
                        ユーザー情報
                    </Typography>
                    <Box sx={{ m: "0.3rem" }}>
                        {view_list.map((val, i) => (
                            <React.Fragment key={i}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Box sx={{ width: "10rem" }}>{val.label}</Box>
                                    <Box>{val.value}</Box>
                                </Grid>
                            </React.Fragment>
                        ))}

                        {user_info?.length &&
                            user_info.map((value, index) => (
                                <React.Fragment key={index}>
                                    <Box sx={{ m: "0.3rem", background: "#faa", p: 1, borderRadius: 1 }}>
                                        {affiliation_list.map((val, i) => (
                                            <React.Fragment key={i}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Box sx={{ width: "10rem" }}>{val.label}</Box>
                                                    <Box>{user_info[index][val.value]}</Box>
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </React.Fragment>
                            ))}
                    </Box>
                </Paper>
            ) : (
                ""
            )}
        </Box>
    );
};

export default UserInfo;
