import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

export const SelectModel = () => {
    const InformationState = useSelector((state) => state.Information);

    let model_list = [{ model_name: "GRATINA" }, { model_name: "iphone" }, { model_name: "Wi-Fiルーター" }];
    if (InformationState?.user_type == "admin") {
        model_list = [{ model_name: "GRATINA" }, { model_name: "iphone" }, { model_name: "Wi-Fiルーター" }, { model_name: "ipad" }];
    }



    const [model, setModel] = React.useState("iphone");

    return (
        <React.Fragment>
            <FormControl sx={{ mb: "1rem" }}>
                <InputLabel id="select-model-label">機種</InputLabel>
                <Select
                    value={model}
                    sx={{ width: "20rem" }}
                    fullWidth
                    // labelId="select-number_BottleKeep-label"
                    label="機種"
                    name="model"
                    // value={Number(number_bottle_keep)}
                    onChange={(e) => setModel(e.target.value)}
                >
                    {model_list.map((model_data, index) => (
                        <MenuItem value={model_data.model_name} key={index}>
                            {model_data.model_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};
// export default NewApplication;
