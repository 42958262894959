import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";
import {
    Individual_set,
    GetBillingDetail,
    GetTimeOrderCall,
    GetCallByDestination,
    GetETCUse,
} from "~slices/IndividualUsageView";

import BillingDetailTable from "~/components/block/tables/BillingDetailTable";
import TimeOrderCallTable from "~/components/block/tables/TimeOrderCallTable";
import CallByDestinationTable from "~/components/block/tables/CallByDestinationTable";
import ETCUseTable from "~/components/block/tables/ETCUseTable";
import { useHistory } from "react-router";

const IndividualUsageView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const IndividualState = useSelector((state) => state.IndividualUsageView);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        let arg = {};
        for (const [key, val] of searchParams) {
            dispatch(Individual_set([key, val]));
            arg[key] = val;
            
        }

        if (searchParams.size == 3) {
            Serch(arg);
        }
    }, []);
    const HandleChange = (e) => {
        dispatch(Individual_set([e.target.name, e.target.value]));
    };

    const HandleSerch = (e) => {
        e.preventDefault();
        Serch();
    };

    const Serch = (arg) => {
        const params = arg
            ? arg
            : {
                  month: IndividualState.month,
                  data_type: IndividualState.data_type,
                  tell: IndividualState.tell,
              };

        const urlSearchParam = new URLSearchParams(params).toString();
        history.push(`/IndividualUsageView` + "/?" + urlSearchParam);

        switch (params.data_type) {
            case "請求明細表":
                dispatch(GetBillingDetail(params));
                break;
            case "日時順通話明細":
                dispatch(GetTimeOrderCall(params));
                break;
            case "発信先別通話明細":
                dispatch(GetCallByDestination(params));
                break;
            case "ETC利用明細":
                dispatch(GetETCUse(params));
                break;

            default:
                console.log(params);
                break;
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h4">ユーザー利用状況表示</Typography>
            <Typography variant="h6">社員様の請求明細と通話明細（日時順・発信先別）をご確認いただけます</Typography>

            <form onSubmit={(e) => HandleSerch(e)}>
                <Box sx={{ m: "1rem" }}>
                    <SelectDataType onChange={(e) => HandleChange(e)} value={IndividualState.data_type} />

                    <SelectMonth onChange={(e) => HandleChange(e)} value={IndividualState.month} />
                </Box>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{ background: "#e9e9e9", padding: "1rem 1rem" }}
                >
                    <Box>
                        <InputTell onChange={(e) => HandleChange(e)} value={IndividualState.tell} />
                        <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                            検索
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ ml: 1, height: "2.5rem" }}
                            onClick={() => dispatch(Individual_set(["tell", ""]))}
                        >
                            クリア
                        </Button>
                    </Box>
                </Grid>
            </form>

            <DataTable />
        </React.Fragment>
    );
};

const DataTable = (props) => {
    const IndividualState = useSelector((state) => state.IndividualUsageView);

    switch (IndividualState.data_type) {
        case "請求明細表":
            return <BillingDetailTable su_data={IndividualState.su_data} />;
            break;
        case "日時順通話明細":
            return (
                <TimeOrderCallTable
                    tm_data={IndividualState.tm_data}
                    tm_groupe_day={IndividualState.tm_groupe_day}
                    tm_sum_call_fee={IndividualState.tm_sum_call_fee}
                />
            );
            break;
        case "発信先別通話明細":
            return <CallByDestinationTable />;
            break;
        case "ETC利用明細":
            return <ETCUseTable />;
            break;

        default:
            console.log(params);
            return "";
            break;
    }
};

const InputTell = (props) => {
    return (
        <>
            <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="電話番号を指定して表示"
                name="tell"
                required
                helperText={
                    <>
                        ※電話番号を入力後「番号指定」ボタンをクリックしてください。
                        <br />
                        ※「クリア」ボタンで、番号指定を解除します。 例） 090-1234-5678"
                    </>
                }
                size="small"
                {...props}
            />
        </>
    );
};

const SelectMonth = (props) => {
    return (
        <>
            <TextField
                required
                InputLabelProps={{ shrink: true }}
                type="month"
                label="請求月"
                name="month"
                size="small"
                {...props}
            />
        </>
    );
};

const SelectDataType = (props) => {
    const data_type_list = [
        {
            name: "請求明細表",
            label: "BillingDetail",
        },
        {
            name: "日時順通話明細",
            label: "TimeOrderCall",
        },
        // {
        //     name: "発信先別通話明細",
        //     label: "CallByDestination",
        // },
        // {
        //     name: "ETC利用明細",
        //     label: "ETCUse",
        // },
    ];
    return (
        <FormControl sx={{ mb: "1rem" }} size="small">
            <InputLabel id="select-model-label">データ選択</InputLabel>
            <Select
                sx={{ width: "20rem" }}
                fullWidth
                label="データ選択"
                name="data_type"
                required
                // value={Number(number_bottle_keep)}
                // onChange={handleNumberBottleKeep}
                {...props}
            >
                {data_type_list.map((data_type, index) => (
                    <MenuItem value={data_type.name} key={index}>
                        {data_type.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default IndividualUsageView;
