import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { GetApproverList, Application_set } from "~slices/Application";

export const SelectApprover = () => {
    const dispatch = useDispatch();

    const ApplicationState = useSelector((state) => state.Application);

    const company_list = ApplicationState?.company_list;
    const department_list = ApplicationState?.department_list;
    const approver_list = ApplicationState?.approver_list;

    const [company, setCompany] = React.useState(false);
    const [department, setDepartment] = React.useState(false);
    const [approver_index, setApproverIndex] = React.useState("");

    useEffect(() => {
        dispatch(GetApproverList());
    }, []);

    const ChengeSelectCompany = (e) => {
        setCompany(e.target.value);
        dispatch(
            GetApproverList({
                company: e.target.value,
            })
        );
    };
    const ChengeSelectDepartment = (e) => {
        setDepartment(e.target.value);

        setApproverIndex("");
        dispatch(
            GetApproverList({
                company: company,
                department: e.target.value,
            })
        );
    };

    const ChengeSelectApprover = (e) => {
        setApproverIndex(e.target.value);

        const selected_approver = approver_list[e.target.value];
        dispatch(Application_set(["selected_approver", selected_approver]));
        dispatch(Application_set(["approver_name", selected_approver.DisplayName]));
        dispatch(Application_set(["approver_email", selected_approver.Address]));
    };

    return (
        <React.Fragment>
            {company_list?.length ? (
                <TextField
                    select
                    value={company}
                    label="会社"
                    onChange={(e) => ChengeSelectCompany(e)}
                    sx={{ minWidth: "20rem", mr: 1 }}
                >
                    {company_list &&
                        company_list.map((company_data) => (
                            <MenuItem key={company_data.Company} value={company_data.Company}>
                                {company_data.Company}
                            </MenuItem>
                        ))}
                </TextField>
            ) : (
                ""
            )}

            {department_list?.length ? (
                <TextField
                    select
                    value={department}
                    label="部署"
                    onChange={(e) => ChengeSelectDepartment(e)}
                    sx={{ minWidth: "20rem", mr: 1 }}
                >
                    {department_list &&
                        department_list.map((company_data) => (
                            <MenuItem key={company_data.Department} value={company_data.Department}>
                                {company_data.Department}
                            </MenuItem>
                        ))}
                </TextField>
            ) : (
                ""
            )}
            {approver_list?.length ? (
                <FormControl sx={{ mb: "1rem" }}>
                    <InputLabel id="select-approver-label">承認者</InputLabel>
                    <Select
                        value={approver_index}
                        sx={{ width: "20rem" }}
                        fullWidth
                        // labelId="select-number_BottleKeep-label"
                        label="承認者"
                        name="approver"
                        // value={Number(number_bottle_keep)}
                        onChange={(e) => ChengeSelectApprover(e)}
                    >
                        {approver_list
                            ? approver_list.map((approver_data, index) => (
                                  <MenuItem value={index} key={index}>
                                      {approver_data.DisplayName}
                                  </MenuItem>
                              ))
                            : ""}
                    </Select>
                </FormControl>
            ) : (
                ""
            )}
            <Box>
                {ApplicationState?.approver_email ? (
                    <>
                        <Stack direction="row" spacing={2} sx={{ p: 1, mt: 1, border: "solid 1px gray" }}>
                            <Box sx={{ m: 1 }}>
                                <Typography>メールアドレス</Typography>

                                {ApplicationState.approver_email}
                            </Box>
                            <Box sx={{ m: 1 }}>
                                <Typography>名前</Typography>
                                {ApplicationState.approver_name}
                            </Box>
                            <Box sx={{ m: 1 }}>
                                <Typography>役職</Typography>
                                {ApplicationState.selected_approver.Title}
                            </Box>
                        </Stack>
                    </>
                ) : (
                    ""
                )}
            </Box>
        </React.Fragment>
    );
};
// export default NewApplication;
