import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostDamage, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { addDays, addMonths, format } from "date-fns";

import { SelectApprover } from "~/components/block/SelectApprover";
import { useHistory } from "react-router";

const Damage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);
    const [broken_type, setBrokenType] = React.useState("");

    const broken_type_list = [
        "端末修理",
        "バッテリー劣化",
        "発信不良(発信不可)",
        "通話が途切れる",
        "常時圏外表示",
        "電波状態が不安定",
        "電源投入不可",
        "充電不可",
        "データ通信不可",
        "電源が落ちる、強制終了される",
        "水濡れ",
        "その他",
    ];

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }
        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        dispatch(
            PostDamage({
                tel: ApplicationState.selected_tel,
                broken_type: broken_type,
                broken_detail: elem?.broken_detail?.value,
                sending_name: elem?.sending_name?.value,
                sending_post_code: elem?.sending_post_code?.value,
                sending_address: elem?.sending_address?.value,
                sending_tel: elem?.sending_tel?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });
    };

    return (
        <React.Fragment>
            <Typography variant="h4">端末修理</Typography>
            携帯電話が端末修理した際の申請です
            <Box
                sx={{
                    pt: "1rem",
                }}
            >
                <Caution />
                <Typography variant="h5">①現在ご利用機種</Typography>
                <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                    <SerchModelData />
                </Box>

                <form onSubmit={HandleSubmit}>
                    <Typography variant="h5">②ご申請内容</Typography>
                    <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                        <FormControl sx={{ mb: "1rem" }}>
                            <InputLabel id="select-model-label"> 端末修理内容</InputLabel>
                            <Select
                                value={broken_type}
                                sx={{ width: "20rem" }}
                                fullWidth
                                // labelId="select-number_BottleKeep-label"
                                label=" 端末修理内容"
                                name="model"
                                // value={Number(number_bottle_keep)}
                                onChange={(e) => setBrokenType(e.target.value)}
                            >
                                {broken_type_list.map((broken_type, index) => (
                                    <MenuItem value={broken_type} key={index}>
                                        {broken_type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box sx={{ mt: 1 }}>状況</Box>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label=""
                            name="broken_detail"
                            multiline
                            rows={4}
                            fullWidth
                            // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>

                    <InputDestination titleNum="③" title="代替機送付先" />
                    <Typography variant="h5">承認者</Typography>
                    <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                        <SelectApprover />
                    </Box>
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </form>
            </Box>
        </React.Fragment>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "2px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect aka">
                <div style={{ margin: "10px 0 5px 10px" }}>【注意】</div>
                <div style={{ margin: "0 20px 10px 10px" }}>
                    故障等の件数が多い枯渇時は手配に時間がかかる可能性があります。
                </div>
            </div>
            <div style={{ border: "2px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    <p>iPhoneが故障した場合の手続きについて</p>
                </div>
                <div style={{ margin: "5px 20px 5px 5px" }}>
                    ・故障交換のフローは以下になります。
                    <div style={{ margin: "0 20px 5px 20px" }}>
                        通信費管理システムからの申請 → 依頼書（※３）への記入 → 依頼書の返信 → 代替機をKDDIから発送 →
                        <br />
                        到着後、故障機からSIMを入れ替えて代替機への切替え（※１）→ 故障機を返送（※２）
                        <br />
                        ※１：切替えマニュアルはNotesのパソコンQ&amp;Aを参照ください。
                        <br />
                        パソコンQ＆A -
                        Notes://Notes_KS2/492563AB002C2786/B12B5577B74E88D949257E30000584D0/6DD040264AB5EFE7492588F00019491F
                        <br />
                        ※２：当日16時までに依頼のものは翌々営業日納品となります。
                        <br />
                        （北海道、九州、沖縄、山口、広島、島根は+1日かかります）
                        <br />
                        ※３：依頼書のリンクは本申請の承認完了メールに記載いたしますので、ファイルをダウンロードし記入してください。
                        <br />
                    </div>
                    ・無償で交換できます。
                    <br />
                    ・故障機の返送先は以下のとおりです。
                    <div style={{ margin: "0 20px 15px 20px" }}>
                        〈ヤマト運輸の場合〉
                        <br />
                        〒243-0303
                        <br />
                        027-600 厚木物流システム支店気付
                        <br />
                        KDDI株式会社 SL レンタルT 係<br />
                        <br />
                        〈ヤマト運輸以外の場合〉
                        <br />
                        〒252-0146
                        <br />
                        神奈川県相模原市緑区大山町4番7号
                        <br />
                        ロジポート橋本303区画
                        <br />
                        KDDI株式会社
                        <br />
                        東日本物流キッティングセンター SL レンタルT 係<br />
                    </div>
                </div>
            </div>
            <div style={{ border: "2px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div
                    style={{
                        textDecoration: "underline",
                        fontSize: 14,
                        margin: "5px 0 10px 10px",
                    }}
                >
                    USBスティック・WiFiルータ故障の場合
                </div>
                <div style={{ margin: "5px 20px 5px 10px" }}>
                    USBスティック・WiFiルータの故障対応は受け付けておりません。
                    <br />
                    故障しているかどうかの切り分けを実施しますので、情報システムヘルプデスクまでご連絡ください。
                    <br />
                    <br />
                    &lt; 問い合わせ先 &gt;
                    <br />
                    情報システムヘルプデスク
                    <br />
                    内線：3900027
                    <br />
                    外線：03-6743-6660
                </div>
            </div>
        </React.Fragment>
    );
};

export default Damage;
