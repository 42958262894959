import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    billing_amount_sum: 0,
    non_taxable_sum: 0,
    commission_sum: 0,
    all_sum: 0,
    line_count: 0,
    commission: 0,
    tax_10_sum: 0,
};
export const GetAllBillingGuidance = (arg) => {
    return AsyncFunction({
        type_name: "AllBillingGuidance/情報の取得",
        method: "post",
        url: "/api/GetAllBillingGuidance",
        params: arg,
    });
};

export const DounLoadAllBillingGuidance = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadAllBillingGuidance/csvダウンロード",
        method: "post",
        url: "/api/DounLoadAllBillingGuidance",
        params: arg,
    });
};

export const DounLoadAccountCode = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadAllBillingGuidance/csvダウンロード",
        method: "post",
        url: "/api/DounLoadAccountCode",
        params: arg,
    });
};

export const DounLoadNonTaxable = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadAllBillingGuidance/csvダウンロード",
        method: "post",
        url: "/api/DounLoadNonTaxable",
        params: arg,
    });
};
export const DounLoadTaxable = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadAllBillingGuidance/csvダウンロード",
        method: "post",
        url: "/api/DounLoadTaxable",
        params: arg,
    });
};

export const DounLoadAnalysisData = (arg) => {
    return AsyncFunction({
        type_name: "DounLoadAllBillingGuidance/csvダウンロード",
        method: "post",
        url: "/api/DounLoadAnalysisData",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "AllBillingGuidance",
    initialState,
    reducers: {
        AllBillingGuidance_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("AllBillingGuidance/") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload.result != "OK") return;
                    if (payload?.billing_amount_sum) {
                        state.billing_amount_sum = payload?.billing_amount_sum;
                    } else {
                        state.billing_amount_sum = 0;
                    }
                    if (payload?.non_taxable_sum) {
                        state.non_taxable_sum = payload?.non_taxable_sum;
                    } else {
                        state.non_taxable_sum = 0;
                    }
                    if (payload?.commission_sum) {
                        state.commission_sum = payload?.commission_sum;
                    } else {
                        state.commission_sum = 0;
                    }
                    if (payload?.all_sum) {
                        state.all_sum = payload?.all_sum;
                    } else {
                        state.all_sum = 0;
                    }
                    if (payload?.commission) {
                        state.commission = payload?.commission;
                    } else {
                        state.commission = 0;
                    }
                    if (payload?.line_count) {
                        state.line_count = payload?.line_count;
                    } else {
                        state.line_count = 0;
                    }
                    if (payload?.tax_10_sum) {
                        state.tax_10_sum = payload?.tax_10_sum;
                    } else {
                        state.tax_10_sum = 0;
                    }
                }
            )

            .addMatcher(
                (action) => action.type.match("DounLoadAllBillingGuidance") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    //CSVデータ
                    const data = action.payload;

                    //ダウンロードするCSVファイル名を指定する
                    const filename = "download.csv";

                    //BOMを付与する（Excelでの文字化け対策）
                    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
                    //Blobでデータを作成する
                    const blob = new Blob([bom, data], { type: "text/csv" });

                    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                        //その他ブラウザ
                    } else {
                        //BlobからオブジェクトURLを作成する
                        const url = (window.URL || window.webkitURL).createObjectURL(blob);
                        //ダウンロード用にリンクを作成する
                        const download = document.createElement("a");
                        //リンク先に上記で生成したURLを指定する
                        download.href = url;
                        //download属性にファイル名を指定する
                        download.download = filename;
                        //作成したリンクをクリックしてダウンロードを実行する
                        download.click();
                        //createObjectURLで作成したオブジェクトURLを開放する
                        (window.URL || window.webkitURL).revokeObjectURL(url);
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { AllBillingGuidance_set } = slice.actions;
