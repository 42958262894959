export const top_menu_data = [
    {
        menu_title: "ユーザー情報",
        menu_item: [
            {
                request: "現在の登録情報を確認したい",
                title: "ユーザー情報表示",
                link: "/RegistrationInfoDisplay",
            },
            {
                request: "パスワードの変更をしたい",
                title: "パスワード変更",
                link: "/ChangePassword",
            },
        ],
    },
    {
        menu_title: "ユーザー利用状況",
        menu_item: [
            {
                request: "請求明細と通話明細（日時順・発信先別）を確認したい",
                title: "ユーザー利用状況表示",
                link: "/IndividualUsageView",
            },
        ],
    },
    {
        menu_title: "登録情報",
        menu_item: [
            {
                request: "人事情報のシステムへの登録状況を確認したい",
                title: "登録情報表示",
                link: "/StaffStatus",
            },
            {
                request: "回線情報を確認したい",
                title: "回線情報表示",
                link: "/MobileManagement",
            },
            // {
            //     request: "部門情報のシステムへの登録状況を確認したい",
            //     title: "部門情報閲覧",
            //     link: "/DeptInfoStatus",
            // },
            // {
            //     request: "携帯電話のシステムへの登録状況 を確認したい",
            //     title: "端末情報閲覧",
            //     link: "/MovingTerminalStatus",
            // },
            // {
            //     request: "固定電話のシステムへの登録状況を確認したい",
            //     title: "一般回線情報閲覧",
            //     link: "/FixedTerminalStatus",
            // },
        ],
    },
    // {
    //     menu_title: "管理者用機能",
    //     menu_item: [
    //         {
    //             request: "インフォメーションに社員向けメッセージを登録したい",
    //             title:"インフォメーション登録",
    //             link: "/",
    //         },
    //         {
    //             request: "ファイルをアップロードしたい",
    //             title:"ファイルアップロード",
    //             link: "/",
    //         },
    //         {
    //             request: "社員情報を追加･修正したい",
    //             title:"社員管理",
    //             link: "/UserManagement",
    //         },
    //     ],
    // },
    {
        menu_title: "申請状況",
        menu_item: [
            {
                request: "各種申請の処理状況を確認したい",
                title: "申請情報表示",
                link: "/ApplicationStatus",
            },
        ],
    },
    {
        menu_title: "各種申込み",
        menu_item: [
            {
                request: "新規に携帯電話を購入したい",
                title: "新規端末申請",
                link: "/NewApplication",
            },
            {
                request: "携帯電話を解約したい",
                title: "解約申請",
                link: "/Cancellation",
            },
            {
                request: "新しい機種に変更したい",
                title: "機種変更申請",
                link: "/ModelChange",
            },
            {
                request: "携帯電話の使用者を変更したい",
                title: "使用者変更",
                link: "/UserChange",
            },
            {
                request: "経費負担コード、利用用途の変更をしたい",
                title: "登録情報変更",
                link: "/ModelInfoChange",
            },
            {
                request: "ACアダプタや電池パックなどアクセサリを購入したい",
                title: "アクセサリ申請",
                link: "/AccessoryPurchase",
            },
            {
                request: "携帯電話が壊れた",
                title: "端末修理申請",
                link: "/Damage",
            },
            {
                request: "携帯電話を紛失した",
                title: "紛失連絡",
                link: "/Loss",
            },
        ],
    },

    // {
    //     menu_title: "ダウンロード",
    //     menu_item: [
    //         {
    //             request: "請求内訳や月次利用実績をダウンロードしたい",
    //             title:"ダウンロード",
    //             link: "/",
    //         },
    //     ],
    // },

    {
        menu_title: "部門別利用明細",
        menu_item: [
            {
                request: "部門の通信費請求内容を確認したい",
                title: "部門別利用明細表示",
                link: "/BillingContentStatus",
            },
            // {
            //     request: "部門の回線種別全体通信費の明細を確認したい",
            //     title: "全体通信費",
            //     link: "/AllUseStatus",
            // },
            // {
            //     request: "部署ごとの社員別の利用状況を確認したい",
            //     title: "社員別利用状況",
            //     link: "/UseStatus",
            // },
            // {
            //     request: "部署ごとの有料サイト利用状況を確認したい",
            //     title: "部署別利用状況",
            //     link: "/PaidSiteUseStatus",
            // },
            // {
            //     request: "部署ごとの通信費の利用推移を確認したい",
            //     title: "部署別利用推移",
            //     link: "/CostTrend",
            // },
        ],
    },

    {
        menu_title: "会社別請求内訳",
        menu_item: [
            {
                request: "全体請求額を確認したい",
                title: "会社別請求内訳表示",
                link: "/AllBillingGuidance",
            },
            // {
            //     request: "通信費の部門振分けを確認したい",
            //     title: "部門振り分け一覧",
            //     link: "/DeptAllocationList",
            // },
            // {
            //     request: "事業者別の請求内訳を確認したい",
            //     title: "電話事業者別通信費",
            //     link: "/CostByCareer",
            // },
        ],
    },

    {
        menu_title: "お問い合わせ",
        menu_item: [
            {
                request: "質問したい",
                title: "お問い合わせ",
                link: "/Contact",
            },
        ],
    },
];
