import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    name_list: [],
    loading: false,
    selected: false,
    error_message: "",
    tm_csv_list: [],
    su_csv_list: [],
    process_list: [],
};

export const GetCSVNameList = (arg) => {
    return AsyncFunction({
        type_name: "UploadCSV/csvデータのリスト",
        method: "post",
        url: "/api/GetCSVNameList",
        params: arg,
        content_type: "multipart/form-data",
    });
};
export const Upload = (arg) => {
    return AsyncFunction({
        type_name: "UploadCSV/csvデータのアップロード",
        method: "post",
        url: "/api/UploadCSV",
        params: arg,
        content_type: "multipart/form-data",
    });
};
// Sliceを生成する
const slice = createSlice({
    name: "UploadCSV",
    initialState,
    reducers: {
        initialize: (state) => (state = initialState),
        UploadCSV_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("UploadCSV") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;

                if (payload?.tm_csv_list) {
                    state.tm_csv_list = payload?.tm_csv_list;
                }
                if (payload?.su_csv_list) {
                    state.su_csv_list = payload?.su_csv_list;
                }
                if (payload?.process_list) {
                    state.process_list = payload?.process_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, UploadCSV_set } = slice.actions;
