import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態

const now_month = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const string_month = month.toString().padStart(2, "0");
    return `${year}-${string_month}`;
};

const initialState = {
    month: now_month(),
    data_type: "日時順通話明細",
    tell: "",
    su_data: [],
    tm_data: [],
    tm_groupe_day: [],
    tm_sum_call_fee: 0,
};

export const GetBillingDetail = (arg) => {
    return AsyncFunction({
        type_name: "IndividualUsageView/請求明細表の取得",
        method: "post",
        url: "/api/GetBillingDetail",
        params: arg,
    });
};
export const GetTimeOrderCall = (arg) => {
    return AsyncFunction({
        type_name: "IndividualUsageView/日時順通話明細の取得",
        method: "post",
        url: "/api/GetTimeOrderCall",
        params: arg,
    });
};

export const GetCallByDestination = (arg) => {
    return AsyncFunction({
        type_name: "IndividualUsageView/発信先別通話明細の取得",
        method: "post",
        url: "/api/GetCallByDestination",
        params: arg,
    });
};

export const GetETCUse = (arg) => {
    return AsyncFunction({
        type_name: "IndividualUsageView/ETC利用明細の取得",
        method: "post",
        url: "/api/GetETCUse",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "IndividualUsageView",
    initialState,
    reducers: {
        Individual_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("IndividualUsageView/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.result != "OK") return;


                const name_list = ['su_data', 'tm_data', 'tm_groupe_day', 'tm_sum_call_fee'];
                name_list.map((value) => {
                    state[value] = payload[value];
                })

                // if (payload?.su_data) {
                //     state.su_data = payload?.su_data;
                // }
                // if (payload?.tm_data) {
                //     state.tm_data = payload?.tm_data;
                // }
                // if (payload?.tm_groupe_day) {
                //     state.tm_groupe_day = payload?.tm_groupe_day;
                // }
                // if (payload?.tm_sum_call_fee) {
                //     state.tm_sum_call_fee = payload?.tm_sum_call_fee;
                // }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Individual_set } = slice.actions;
