import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
/**
 * type_name
 * method
 * url
 * params
 * api_key
 */
export const AsyncFunction = function (arg) {
    const AsyncFunc = createAsyncThunk(arg.type_name, async (arg, thunkAPI) => {
        try {
            let { method, url, params, api_key, content_type = "application/json" } = arg;
            const informationState = thunkAPI.getState().Information;

            // let key = api_key;
            // switch (api_key) {
            //   case "admin":
            //     key = informationState.admin?.admin_token;
            //     break;
            //   case "user":
            //     key = informationState.user?.token;
            //     break;
            //   case "shop":
            //     key = informationState.shop?.shop_token;
            //     break;
            //   default:
            //     break;
            // }

      
            const key = api_key ? api_key : informationState?.user_token;

            const headers = {
                headers: {
                    "API-KEY": key,
                    // "Content-Type": content_type,
                    withCredentials: true,
                },
            };

            const encodedurl = url + "?" + new URLSearchParams(params);

            let response;

            switch (method.toLowerCase()) {
                case "get":
                    response = await axios.get(encodedurl, headers);
                    break;
                case "post":
                    response = await axios.post(url, params, headers);
                    break;
                case "put":
                    response = await axios.put(url, params, headers);
                    break;
                case "delete":
                    response = await axios.delete(encodedurl, headers);
                    break;
                default:
                    response = await axios.get(encodedurl, headers);
                    break;
            }

            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    });
    return AsyncFunc(arg);
};
