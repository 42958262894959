import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    config: {
        commission: 300,
    },
    user_info: [],
    user_type: "",
    login_status: false, //false,user
    user_token: "",
};
export const TokenLogin = (arg) => {
    return AsyncFunction({
        type_name: "Information/トークンログイン",
        method: "post",
        url: "/api/TokenLogin",
        params: arg,
    });
};

export const UserLogin = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーログイン",
        method: "post",
        url: "/api/UserLogin",
        params: arg,
    });
};

export const UserSignup = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザー新規登録",
        method: "post",
        url: "/api/UserSignup",
        params: arg,
    });
};

export const UserInfoPost = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザー詳細登録",
        method: "post",
        url: "/api/UserInfoPost",
        params: arg,
    });
};

export const PutPassword = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーパスワード変更",
        method: "put",
        url: "/api/ChangePassword",
        params: arg,
    });
};
export const PutEmail = (arg) => {
    return AsyncFunction({
        type_name: "Information/ユーザーメールアドレスの変更",
        method: "put",
        url: "/api/ChangeEmail",
        params: arg,
    });
};

export const PostUserOneTime = (arg) => {
    return AsyncFunction({
        type_name: "OneTime/ユーザーワンタイムパスワードの申請",
        method: "post",
        url: "/api/PostUserOneTime",
        params: arg,
    });
};

export const PostConfig = (arg) => {
    return AsyncFunction({
        type_name: "Information/設定の登録",
        method: "post",
        url: "/api/PostConfig",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Information",
    initialState,
    reducers: {
        Information_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        UserLogout: (state, action) => {
            state = { ...initialState, login_status: "" };
            localStorage.removeItem("user_token");
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("Information/") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload.result != "OK") return;

                    if (payload?.user_info) {
                        state.user_info = payload?.user_info;
                    }

                    if (payload?.user_token) {
                        state.user_token = payload?.user_token;
                        state.login_status = "user";
                        localStorage.setItem("user_token", payload?.user_token);
                    }

                    if (payload?.user_type) {
                        state.user_type = payload?.user_type;
                    }

                    if (payload?.config) {
                        state.config = payload?.config;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("Information/トークンログイン"),
                (state, action) => {
                    // トークンログインに失敗した場合、トップページにリダイレクト
                    switch (true) {
                        case action.type.endsWith("/fulfilled") && action.payload.result == "NG":
                            state.login_status = "failure";
                            break;
                        case action.type.endsWith("/rejected"):
                            state.login_status = "failure";
                            break;
                        default:
                            break;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Information_set, UserLogout } = slice.actions;
