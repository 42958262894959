import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    company_list: [],
    department_list: [],
    user_list: [],
};
export const GetAffiliationSelectList = (arg) => {
    return AsyncFunction({
        type_name: "UserAffiliation/ユーザー情報の取得",
        method: "get",
        url: "/api/GetAffiliationSelectList",
        params: arg,
    });
};
// Sliceを生成する
const slice = createSlice({
    name: "UserAffiliation",
    initialState,
    reducers: {
        UserAffiliation_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("UserAffiliation/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.company_list && payload.company_list.length != 0) {
                    state.company_list = payload.company_list;
                }
                if (payload.department_list && payload.department_list.length != 0) {
                    state.department_list = payload.department_list;
                }
                if (payload.user_list) {
                    state.user_list = payload.user_list;
                }
                // if (payload.result != "OK") return;
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { UserAffiliation_set } = slice.actions;
