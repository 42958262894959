import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    user_list: [],
    user_info: {
        //ユーザー作成
        permission: "一般",
    },
    user_info_fix: {}, //ユーザー情報修正
    select_user_row: {}, //選択ユーザー
    open_create_user: false,
    open_fix_user: false,
};
export const GetUserList = (arg) => {
    return AsyncFunction({
        type_name: "UserManagement/ユーザー情報の取得",
        method: "get",
        url: "/api/GetUserList",
        params: arg,
    });
};

export const CreateUser = (arg) => {
    return AsyncFunction({
        type_name: "UserManagement/ユーザーの登録",
        method: "post",
        url: "/api/CreateUser",
        params: arg,
    });
};

export const FixUser = (arg) => {
    return AsyncFunction({
        type_name: "UserManagement/ユーザー情報の変更",
        method: "put",
        url: "/api/FixUser",
        params: arg,
    });
};

export const DeleteUser = (arg) => {
    return AsyncFunction({
        type_name: "UserManagement/ユーザー情報の削除",
        method: "delete",
        url: "/api/DeleteUser",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "UserManagement",
    initialState,
    reducers: {
        UserManagement_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            const type = action.payload[2];

            if (type) {
                state[type][name] = value;
            } else {
                state[name] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("UserManagement/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;

                if (payload.user_list) {
                    state.user_list = payload.user_list;
                    console.log(state.user_list);
                }
                // if (payload.result != "OK") return;
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { UserManagement_set } = slice.actions;
