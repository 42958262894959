import React, { Infoef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set } from "~slices/UploadCSV";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { InfoManagement_set, GetInfoList, CreateInfo, DeleteInfo } from "~slices/InfoManagement";

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import Popup from "~/components/block/Popup";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const TimeOrderCallTable = (props) => {
    const dispatch = useDispatch();
    const { tm_data = [], tm_groupe_day = [], tm_sum_call_fee = 0 } = props;

    const columns = [
        { field: "outgoing_phone", headerName: "発信元", minWidth: 130 },
        { field: "call_date", headerName: "通話年月日" },
        { field: "call_start_time", headerName: "通話開始時刻" },
        { field: "incoming_phone", headerName: "相手方電話番号", minWidth: 130 },
        { field: "outgoing_area", headerName: "通話先県名" },
        { field: "incoming_area", headerName: "着信先地域" },
        { field: "call_target_kubun", headerName: "通話先区分" },
        { field: "detail_type", headerName: "明細種別" },
        { field: "call_time", headerName: "通話時間" },
        { field: "call_fee", headerName: "通話料金", align: "right" },
        { field: "bytes_count", headerName: "バイト数" },
        { field: "11", headerName: "通話種別" },
        { field: "12", headerName: "昼夜深種別" },
        { field: "13", headerName: "割引種別" },
        { field: "14", headerName: "用途別区分" },
    ];

    return (
        <React.Fragment>
            {tm_groupe_day && tm_groupe_day.length ? (
                <Box sx={{ mt: 1 }}>
                    日付別通話料金
                    <TableContainer
                        // component={Paper}
                        sx={{ border: "solid 1px #d1d1d1", borderRadius: 1, display: "table-caption" }}
                    >
                        <Table sx={{ width: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>通話年月日</TableCell>
                                    <TableCell align="right">件数</TableCell>
                                    <TableCell align="right">通話料金</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tm_groupe_day.map((row) => (
                                    <TableRow
                                        key={row.call_date}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell>{row.call_date}</TableCell>
                                        <TableCell align="right">{row.count_call_fee}</TableCell>
                                        <TableCell align="right">¥{row.total_call_fee}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell>合計</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">¥{tm_sum_call_fee}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                ""
            )}

            {tm_data && tm_data.length ? (
                <Box
                    sx={{
                        // minHeight: 900,
                        height: 900,
                        width: "100%",
                        mt: "1rem", // minWidth: "100%"
                        display: "grid",
                    }}
                >
                    <DataGrid
                        rows={tm_data}
                        columns={columns}
                        // rowCount={party_list_total}
                        // pageSize={10}
                        // onPageChange={setPage}
                        // paginationMode="server"
                        // sortingMode="server"
                        // rowsPerPageOptions={[limit]}
                        // disableColumnMenu

                        // initialState={{
                        //     columns: {
                        //         columnVisibilityModel: {
                        //             Info_id: false,
                        //         },
                        //     },
                        // }}
                        disableCellSelectionOnClick
                        disableSelectionOnClick
                        pagination
                        // onRowClick={(e) => HandleRowClick(e.row)}
                        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default TimeOrderCallTable;
