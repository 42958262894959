import * as React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import TreeView from "@mui/lab/TreeView";
// import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import DeleteIcon from "@mui/icons-material/Delete";
import Label from "@mui/icons-material/Label";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import InfoIcon from "@mui/icons-material/Info";
import ForumIcon from "@mui/icons-material/Forum";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";


// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//     color: theme.palette.text.secondary,
//     [`& .${treeItemClasses.content}`]: {
//         color: theme.palette.text.secondary,
//         borderTopRightRadius: theme.spacing(2),
//         borderBottomRightRadius: theme.spacing(2),
//         paddingRight: theme.spacing(1),
//         fontWeight: theme.typography.fontWeightMedium,
//         "&.Mui-expanded": {
//             fontWeight: theme.typography.fontWeightRegular,
//         },
//         "&:hover": {
//             backgroundColor: theme.palette.action.hover,
//         },
//         "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//             backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//             color: "var(--tree-view-color)",
//         },
//         [`& .${treeItemClasses.label}`]: {
//             fontWeight: "inherit",
//             color: "inherit",
//         },
//     },
//     [`& .${treeItemClasses.group}`]: {
//         marginLeft: 0,
//         [`& .${treeItemClasses.content}`]: {
//             paddingLeft: theme.spacing(2),
//         },
//     },
// }));

function StyledTreeItem(props) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        "--tree-view-color": theme.palette.mode !== "dark" ? color : colorForDarkMode,
        "--tree-view-bg-color": theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    bgColorForDarkMode: PropTypes.string,
    color: PropTypes.string,
    colorForDarkMode: PropTypes.string,
    // labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

export default function NaviTree(props) {
    const { menuData = [] } = props;
    return (
        <TreeView
            aria-label="gmail"
            defaultExpanded={["3"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto", display: "inline" }}
        >
            {menuData.map((data, i) => (
                <StyledTreeItem nodeId={String(i + 1)} labelText={data.menu_title} labelIcon={Label} key={i}>
                    {data.menu_item.map((item, j) => (
                        <React.Fragment key={j}>
                            {item.title && (
                                <Link to={item.link}>
                                    <StyledTreeItem
                                        nodeId={String(j + (i + 1) * 10)}
                                        labelText={item.title}
                                        // labelIcon={SupervisorAccountIcon}
                                        // labelInfo="90"
                                        color="#1a73e8"
                                        bgColor="#e8f0fe"
                                        colorForDarkMode="#B8E7FB"
                                        bgColorForDarkMode="#071318"
                                    />
                                </Link>
                            )}
                        </React.Fragment>
                    ))}
                </StyledTreeItem>
            ))}
        </TreeView>
    );
}
