import React, { useLayoutEffect, useEffect, useState } from "react";

import { Route, useLocation, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { green, orange, blue } from "@mui/material/colors";

import Top from "~/components/pages/Top";
import Login from "~/components/pages/Login";
import Contact from "~/components/pages/Contact";
import ChangePassword from "~/components/pages/ChangePassword";
import StaffStatus from "~/components/pages/StaffStatus";
import ErrerPopup from "~/components/popups/ErrerPopup";

import NewApplication from "~/components/pages/mobile_application/NewApplication";
import ModelChange from "~/components/pages/mobile_application/ModelChange";
import Cancellation from "~/components/pages/mobile_application/Cancellation";
import ModelInfoChange from "~/components/pages/mobile_application/ModelInfoChange";
import UserChange from "~/components/pages/mobile_application/UserChange";
import AccessoryPurchase from "~/components/pages/mobile_application/AccessoryPurchase";
import Damage from "~/components/pages/mobile_application/Damage";
import Loss from "~/components/pages/mobile_application/Loss";

import UploadCSV from "~/components/pages/admin/UploadCSV";
import UserManagement from "~/components/pages/admin/UserManagement";
import InfoManagement from "~/components/pages/admin/InfoManagement";
import MobileManagement from "~/components/pages/applicationViewing/MobileManagement";

import MasterWriting from "~/components/pages/admin/MasterWriting";
import Config from "~/components/pages/admin/Config";
import InsertNewMobile from "~/components/pages/admin/InsertNewMobile";

import RegistrationInfoDisplay from "~/components/pages/UserInfo/RegistrationInfoDisplay";

import IndividualUsageView from "~/components/pages/personal_usage/IndividualUsageView";

import DeptInfoStatus from "~/components/pages/InfoViewing/DeptInfoStatus";
import MovingTerminalStatus from "~/components/pages/InfoViewing/MovingTerminalStatus";
import FixedTerminalStatus from "~/components/pages/InfoViewing/FixedTerminalStatus";

import BillingContentStatus from "~/components/pages/deptUseItem/BillingContentStatus";
import AllUseStatus from "~/components/pages/deptUseItem/AllUseStatus";
import UseStatus from "~/components/pages/deptUseItem/UseStatus";
import CostTrend from "~/components/pages/deptUseItem/CostTrend";

import AllBillingGuidance from "~/components/pages/billingDetail/AllBillingGuidance";
import DeptAllocationList from "~/components/pages/billingDetail/DeptAllocationList";

import ApplicationStatus from "~/components/pages/applicationViewing/ApplicationStatus";

import { TokenLogin, Information_set } from "~slices/Information";

import NaviBar from "~/components/block/NaviBar";
import Popup from "~/components/block/Popup";
import ModalProgress from "~/components/block/ModalProgress";

import { useHistory } from "react-router";


import "../../css/app.css";
const theme = createTheme({
    palette: {
        primary: {
            main: blue[400],
        },
        // secondary: {
        //     main: green[500],
        // },
    },
});

const App = (state) => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const history = useHistory();

    useLayoutEffect(() => {
        const user_token = localStorage.getItem("user_token");
        if (!user_token) {
            dispatch(Information_set(["login_status", false]));
            history.push("/login");
            return;
        }

        const params = {
            user_token: user_token,
        };

        dispatch(TokenLogin(params)).then((data) => {

        });

    }, []);





    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popup />

                <ErrerPopup />
                <ModalProgress />

                <NaviBar>
                    <Switch>
                        {/* 共通 */}

                        <Route path="/Login" component={Login} exact />

                        {informationState?.login_status ? (
                            <>
                                <Route
                                    path="/"
                                    component={() => (
                                        <>
                                            <Top />
                                        </>
                                    )}
                                    exact
                                />
                                <Route path="/Contact" component={Contact} exact />
                                <Route path="/NewApplication" component={NewApplication} exact />
                                <Route path="/ModelChange" component={ModelChange} exact />
                                <Route path="/Cancellation" component={Cancellation} exact />
                                <Route path="/ModelInfoChange" component={ModelInfoChange} exact />
                                <Route path="/UserChange" component={UserChange} exact />
                                <Route path="/AccessoryPurchase" component={AccessoryPurchase} exact />
                                <Route path="/Damage" component={Damage} exact />
                                <Route path="/Loss" component={Loss} exact />
                                <Route path="/ChangePassword" component={ChangePassword} exact />

                                <Route path="/IndividualUsageView" component={IndividualUsageView} exact />

                                {/* ユーザー情報 */}
                                <Route path="/RegistrationInfoDisplay" component={RegistrationInfoDisplay} exact />

                                {/* 登録情報表示 */}
                                <Route path="/StaffStatus" component={StaffStatus} exact />
                                <Route path="/MobileManagement" component={MobileManagement} exact />
                                
                                <Route path="/DeptInfoStatus" component={DeptInfoStatus} exact />
                                <Route path="/MovingTerminalStatus" component={MovingTerminalStatus} exact />
                                <Route path="/FixedTerminalStatus" component={FixedTerminalStatus} exact />

                                {/* 申請状況確認 */}
                                <Route path="/ApplicationStatus" component={ApplicationStatus} exact />

                                {informationState?.user_type == "admin" ? (
                                    <>
                                        {/* 管理機能 */}
                                        <Route path="/UserManagement" component={UserManagement} exact />
                                        <Route path="/UploadCSV" component={UploadCSV} exact />
                                        <Route path="/InfoManagement" component={InfoManagement} exact />
                                        <Route path="/MasterWriting" component={MasterWriting} exact />
                                        <Route path="/Config" component={Config} exact />
                                        <Route path="/InsertNewMobile" component={InsertNewMobile} exact />
                                       
                                    </>
                                ) : (
                                    ""
                                )}

                                {/* 部門利用明細 */}
                                <Route path="/BillingContentStatus" component={BillingContentStatus} exact />
                                <Route path="/AllUseStatus" component={AllUseStatus} exact />
                                <Route path="/UseStatus" component={UseStatus} exact />
                                <Route path="/CostTrend" component={CostTrend} exact />

                                {/* 請求内訳詳細 */}
                                <Route path="/AllBillingGuidance" component={AllBillingGuidance} exact />
                                <Route path="/DeptAllocationList" component={DeptAllocationList} exact />
                            </>
                        ) : (
                            ""
                        )}
                        {/* {informationState?.login_status ? (
                        <>
                            <Route path="/UserInfo" component={UserInfo} exact />
                            <Route path="/User" component={User} exact />
                            <Route path="/ChangePassword" component={ChangePassword} exact />
                            <Route path="/ChangeEmail" component={ChangeEmail} exact />
                            <Route path="/Chat" component={Chat} exact />
                            <Route path="/ChatList" component={ChatList} exact />
                            <Route path="/TimeLine" component={TimeLine} exact />
                        </>
                    ) : (
                        ""
                    )} */}
                    </Switch>
                </NaviBar>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
