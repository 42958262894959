import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    user_list: [],
};
export const SerchStaffStatus = (arg) => {
    return AsyncFunction({
        type_name: "StaffStatus/ユーザー情報の取得",
        method: "post",
        url: "/api/SerchStaffStatus",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "StaffStatus",
    initialState,
    reducers: {
        StaffStatus_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("StaffStatus/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.result != "OK") return;

                if (payload?.user_list) {
                    state.user_list = payload?.user_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { StaffStatus_set } = slice.actions;
