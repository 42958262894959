import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set } from "~slices/UploadCSV";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { UserManagement_set, CreateUser, GetUserList, DeleteUser } from "~slices/UserManagement";
import { GetAffiliationSelectList } from "~slices/UserAffiliation";
import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";

const UserManagement = () => {
    const dispatch = useDispatch();
    const UserManagementState = useSelector((state) => state.UserManagement);

    React.useEffect(() => {
        dispatch(GetUserList());
    }, []);

    // React.useEffect(() => {
    //     if (UserManagementState.open_create_user) {
    //         console.log("true");
    //     }
    // }, [UserManagementState.open_create_user]);

    const HandleOpenForm = (form_name) => {
        dispatch(UserManagement_set([form_name, true]));
    };

    return (
        <React.Fragment>
            <Typography variant="h4">管理ユーザー管理</Typography>
            <Typography>社員情報を管理します。</Typography>
            <Button
                variant="contained"
                onClick={() => HandleOpenForm("open_create_user")}
                sx={{ width: "10rem", height: "5rem", m: 1 }}
                disabled={UserManagementState.open_create_user == true || UserManagementState.open_fix_user == true}
            >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <PersonAddIcon style={{ fontSize: "40px" }} />
                    管理者登録
                </Grid>
            </Button>
            <Stack sx={{ pb: "1rem", pt: "1rem" }}>{UserManagementState.open_create_user && <CreateUserForm />}</Stack>

            <UserListTable />
        </React.Fragment>
    );
};

const UserListTable = (props) => {
    const dispatch = useDispatch();
    const UserManagementState = useSelector((state) => state.UserManagement);

    const columns = [
        { headerName: "表示名", field: "UpdateDisplayName", minWidth: 130 },
        { headerName: "Eメールアドレス", field: "UpdateAddress", minWidth: 250 },
        { headerName: "会社", field: "Company", minWidth: 200 },
        { headerName: "部署", field: "Department", flex: true },
        { headerName: "役職", field: "Title" },
        {
            headerName: "",
            field: "delete",
            sortable: false,
            renderCell: (user_data) => (
                <Button onClick={() => HandleDeleteUser(user_data.row)} color="error">
                    削除
                </Button>
            ),
        },
    ];

    const HandleDeleteUser = (user_data) => {
        dispatch(DeleteUser({ email: user_data.UpdateAddress }));
    };

    const HandleRowClick = (row) => {
        dispatch(UserManagement_set(["select_user_row", row]));
        dispatch(UserManagement_set(["user_info_fix", row]));
    };
    return (
        <Box
            sx={{
                height: 800,
                width: "100%",
                mt: "1rem",
            }}
        >
            管理者一覧
            <DataGrid
                getRowId={(row) => row.id}
                rows={UserManagementState.user_list}
                columns={columns}
                // rowCount={party_list_total}
                // pageSize={limit}
                // onPageChange={setPage}
                // paginationMode="server"
                // sortingMode="server"
                // rowsPerPageOptions={[limit]}
                // disableColumnMenu

                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            user_id: false,
                            middle_name: false,
                        },
                    },
                }}
                disableCellSelectionOnClick
                disableSelectionOnClick
                pagination
                onRowClick={(e) => HandleRowClick(e.row)}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>
    );
};

const CreateUserForm = (props) => {
    const dispatch = useDispatch();
    const UserManagementState = useSelector((state) => state.UserManagement);

    const HandleSubmit = (email) => {
        dispatch(
            CreateUser({
                new_user_data: { email: email },
            })
        );
    };
    const InputChenge = (e, table) => {
        dispatch(UserManagement_set([e.target.name, e.target.value, table]));
    };
    const HandleCopy = () => {
        dispatch(UserManagement_set(["user_info", UserManagementState.select_user_row]));
    };

    return (
        <Paper elevation={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ background: "#42a5f5" }}>
                <Box sx={{ pl: 1 }}>社員登録</Box>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => dispatch(UserManagement_set(["open_create_user", false]))}
                >
                    閉じる
                </Button>
            </Stack>

            <Box sx={{ p: 1 }}>
                <SelectUser onSet={(select_uiser_data) => HandleSubmit(select_uiser_data.Address)} buttonLabel="追加" />
            </Box>
        </Paper>
    );
};

// const FixUserForm = (props) => {
//     const dispatch = useDispatch();
//     const UserManagementState = useSelector((state) => state.UserManagement);

//     const HandleSubmit = (e) => {
//         e.preventDefault();
//         // const elem = e.target.elements;
//         dispatch(
//             FixUser({
//                 new_user_data: UserManagementState.user_info_fix,
//             })
//         );
//     };
//     const InputChenge = (e, table) => {
//         dispatch(UserManagement_set([e.target.name, e.target.value, table]));
//     };

//     return (
//         <Paper elevation={3}>
//             <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ background: "#42a5f5" }}>
//                 <Box sx={{ pl: 1 }}>社員登録情報変更</Box>
//                 <Button
//                     color="error"
//                     variant="contained"
//                     onClick={() => dispatch(UserManagement_set(["open_fix_user", false]))}
//                 >
//                     閉じる
//                 </Button>
//             </Stack>

//             <Box sx={{ p: 1 }}>
//                 <form onSubmit={(e) => HandleSubmit(e)} id="fix_user_form">
//                     <Stack spacing={1} sx={{ m: 1 }}>
//                         <TextField value={UserManagementState.user_info_fix.user_id} disabled label="ユーザーID" />
//                         {m_user.map((column, index) => (
//                             <React.Fragment key={index}>
//                                 <TextField
//                                     value={
//                                         UserManagementState.user_info_fix[column.name]
//                                             ? UserManagementState.user_info_fix[column.name]
//                                             : ""
//                                     }
//                                     onChange={(e) => InputChenge(e, "user_info_fix")}
//                                     {...column}
//                                 >
//                                     {column?.select_items &&
//                                         column.select_items.map((option) => (
//                                             <MenuItem key={option.value} value={option.value}>
//                                                 {option.label}
//                                             </MenuItem>
//                                         ))}
//                                 </TextField>
//                             </React.Fragment>
//                         ))}
//                     </Stack>
//                 </form>
//                 <Stack spacing={1} sx={{ m: 1 }}>
//                     <Button
//                         form="fix_user_form"
//                         variant="contained"
//                         type="submit"
//                         // disabled={!UploadCSVState.name_list.length || UploadCSVState.error_message !== ""}
//                     >
//                         登録
//                     </Button>
//                 </Stack>
//             </Box>
//         </Paper>
//     );
// };
export default UserManagement;
