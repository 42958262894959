import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {};

export const PostContact = (arg) => {
    return AsyncFunction({
        type_name: "Contact/お問い合わせの送信",
        method: "post",
        url: "/api/PostContact",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Contact",
    initialState,
    reducers: {
        Contact_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Contact/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Contact_set } = slice.actions;
