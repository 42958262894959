import React, { useEffect, useState, useLayoutEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";


import NaviDrawer from "~/components/block/NaviDrawer";

export default function NaviBar(props) {
    const informationState = useSelector((state) => state.Information);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        const path = history.location.pathname;
        // ログインステータスによってリダイレクトするかを判定する。
        // switch (informationState.login_status) {
        //     case "user":
        //         if (path == "/login" || path == "/signup") {
        //             history.push("/");
        //         }
        //         break;
        //     case "failure":
        //         history.push("/login");
        //         break;
        //     case "logout":
        //         history.push("/login");
        //         break;
        //     default:
        //         break;
        // }
    }, [informationState.login_status]);

    const openDrawer = () => {
        setOpen(true);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const onLink = (link) => {
        handleClose();
        history.push(link);
    };

    return (
        <Box sx={{ flexGrow: 1, height: "4rem" }}>
            <NaviDrawer open={open} onClose={() => setOpen(false)}>
                <>
                    {/* <AppBar position="fixed">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={openDrawer}
                            >
                                <MenuIcon />
                            </IconButton>

                            <img src="/images/logo/kurita_ss_logo_n.gif" />
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => onLink("/")}>
                           
                            </Typography>
                        </Toolbar>
                    </AppBar> */}

                    {props.children}
                </>
            </NaviDrawer>
        </Box>
    );
}
