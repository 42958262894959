import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

const ModalProgress = () => {
    const ProgressState = useSelector((state) => state.Progress);
    const [open, setOpen] = React.useState(false);

    // 0.1秒待つ
    const timer = setTimeout(() => {
        setOpen(false);
    }, 300);

    React.useEffect(() => {
        if (ProgressState?.Progress) {
            setOpen(true);
        } else {
            return () => clearTimeout(timer);
        }
    }, [ProgressState?.Progress]);

    return (
        <>
            <Modal open={ProgressState?.Progress}>
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                    <CircularProgress size="5rem" />
                </Grid>
            </Modal>
        </>
    );
};

export default ModalProgress;
