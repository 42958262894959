import React, { Infoef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set } from "~slices/UploadCSV";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import { InfoManagement_set, GetInfoList, CreateInfo, DeleteInfo } from "~slices/InfoManagement";

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Card,
    Paper,
} from "@mui/material";

import Popup from "~/components/block/Popup";

import { DataGrid, jaJP } from "@mui/x-data-grid";

//フォームの内容
const m_Info = [
    { label: "タイトル", name: "title", required: true },
    { label: "内容", name: "content", required: false, multiline: true, minRows: 4 },

    {
        label: "公開対象",
        name: "target",
        select: true,
        select_items: [
            { value: "すべて", label: "すべて" },
            { value: "一般", label: "一般" },
            { value: "管理者", label: "管理者" },
        ],
        required: true,
    },
    { label: "公開日", name: "release_date", required: false, type: "date" },
    { label: "公開終了日", name: "end_date", required: false, type: "date" },
];

const InfoManagement = () => {
    const dispatch = useDispatch();
    const InfoManagementState = useSelector((state) => state.InfoManagement);

    React.useEffect(() => {
        dispatch(GetInfoList());
    }, []);

    const HandleOpenForm = (form_name) => {
        dispatch(InfoManagement_set(["open_create_Info", true]));
    };

    return (
        <React.Fragment>
            <Typography variant="h4">お知らせ管理</Typography>

            <Typography>インフォメーション情報を管理します。</Typography>

            <Button
                variant="contained"
                onClick={() => HandleOpenForm("open_create_Info")}
                sx={{ width: "10rem", height: "5rem", m: 1 }}
                disabled={InfoManagementState.open_create_Info == true}
            >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <PersonAddIcon style={{ fontSize: "40px" }} />
                    お知らせ追加
                </Grid>
            </Button>

            <Popup
                open={InfoManagementState.open_info_detail}
                handleClose={() => dispatch(InfoManagement_set(["open_info_detail", false]))}
                title={InfoManagementState.selected_info_data.title}
                dialogActions={
                    <Button
                        onClick={() =>
                            dispatch(DeleteInfo({ delete_data: InfoManagementState.selected_info_data })).then(() =>
                                dispatch(InfoManagement_set(["open_info_detail", false]))
                            )
                        }
                    >
                        削除
                    </Button>
                }
            >
                <div
                    className="html-preview"
                    dangerouslySetInnerHTML={{
                        __html: InfoManagementState?.selected_info_data?.content?.replace(/\n/g, "<br>"),
                    }}
                ></div>
            </Popup>

            <Stack sx={{ pb: "1rem", pt: "1rem" }}>{InfoManagementState.open_create_Info && <CreateInfoForm />}</Stack>
            <InfoListTable />
        </React.Fragment>
    );
};

const InfoListTable = (props) => {
    const dispatch = useDispatch();
    const InfoManagementState = useSelector((state) => state.InfoManagement);

    const ClickDetail = (info_data) => {
        dispatch(InfoManagement_set(["selected_info_data", info_data]));
        dispatch(InfoManagement_set(["open_info_detail", true]));
    };

    const columns = [
        // { headerName: "ID", field: "id", minWidth: 100, },
        { headerName: "タイトル", field: "title", flex: true },
        { headerName: "公開対象", field: "target", minWidth: 130 },
        { headerName: "公開日", field: "release_date", hideable: true },
        { headerName: "公開終了日", field: "end_date", minWidth: 130 },
        {
            headerName: "詳細",
            field: "detail_button",
            minWidth: 130,
            sortable: false,
            renderCell: (info_data) => <Button onClick={() => ClickDetail(info_data.row)}>詳細</Button>,
        },
    ];

    const HandleRowClick = (row) => {
        dispatch(InfoManagement_set(["select_Info_row", row]));
        dispatch(InfoManagement_set(["Info_info_fix", row]));
    };
    return (
        <Box
            sx={{
                height: 800,
                width: "100%",
                mt: "1rem", // minWidth: "100%"
            }}
        >
            <DataGrid
                getRowId={(row) => row.id}
                rows={InfoManagementState.info_list}
                columns={columns}
                // rowCount={party_list_total}
                // pageSize={limit}
                // onPageChange={setPage}
                // paginationMode="server"
                // sortingMode="server"
                // rowsPerPageOptions={[limit]}
                // disableColumnMenu

                // initialState={{
                //     columns: {
                //         columnVisibilityModel: {
                //             Info_id: false,
                //         },
                //     },
                // }}
                disableCellSelectionOnClick
                disableSelectionOnClick
                pagination
                onRowClick={(e) => HandleRowClick(e.row)}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>
    );
};

const CreateInfoForm = (props) => {
    const dispatch = useDispatch();
    const InfoManagementState = useSelector((state) => state.InfoManagement);

    const HandleSubmit = (e) => {
        e.preventDefault();
        // const elem = e.target.elements;
        dispatch(
            CreateInfo({
                new_Info_data: InfoManagementState.info_data,
            })
        );
    };
    const InputChenge = (e, table) => {
        dispatch(InfoManagement_set([e.target.name, e.target.value, table]));
    };

    return (
        <Paper elevation={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ background: "#42a5f5" }}>
                <Box sx={{ pl: 1 }}>社員登録</Box>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => dispatch(InfoManagement_set(["open_create_Info", false]))}
                >
                    閉じる
                </Button>
            </Stack>

            <Box sx={{ p: 1 }}>
                <form onSubmit={(e) => HandleSubmit(e)} id="create_Info_form">
                    <Stack spacing={1} sx={{ m: 1 }}>
                        {m_Info.map((column, index) => (
                            <React.Fragment key={index}>
                                <TextField
                                    value={
                                        InfoManagementState?.info_data[column.name]
                                            ? InfoManagementState.info_data[column.name]
                                            : ""
                                    }
                                    onChange={(e) => InputChenge(e, "info_data")}
                                    {...column}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ mb: "1rem" }}
                                >
                                    {column?.select_items &&
                                        column.select_items.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </React.Fragment>
                        ))}
                    </Stack>
                </form>
                <Stack spacing={1} sx={{ m: 1 }}>
                    <Button form="create_Info_form" variant="contained" type="submit">
                        登録
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default InfoManagement;
