import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態

const now_month = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const string_month = month.toString().padStart(2, "0");
    return `${year}-${string_month}`;
};

const initialState = {
    application_list: [],
    search_item: [],
    selected_tel: "",
    model_data: false,

    company_list: [],
    department_list: [],
    approver_list: [],
    approver_name: "",
    approver_email: "",
    selected_approver: {},

    user_list: false,
};

export const GetApplicationList = (arg) => {
    return AsyncFunction({
        type_name: "Application/申請一覧の取得",
        method: "post",
        url: "/api/GetApplicationList",
        params: arg,
    });
};

export const GetModelData = (arg) => {
    return AsyncFunction({
        type_name: "Application/端末データの取得",
        method: "post",
        url: "/api/GetModelData",
        params: arg,
    });
};

export const GetApproverList = (arg) => {
    return AsyncFunction({
        type_name: "Application/承認者の取得",
        method: "get",
        url: "/api/GetApproverList",
        params: arg,
    });
};

export const PostNewApplication = (arg) => {
    return AsyncFunction({
        type_name: "Application/新規申請",
        method: "post",
        url: "/api/PostNewApplication",
        params: arg,
    });
};
export const PostModelChange = (arg) => {
    return AsyncFunction({
        type_name: "Application/機種変更",
        method: "post",
        url: "/api/PostModelChange",
        params: arg,
    });
};

export const PostCancellation = (arg) => {
    return AsyncFunction({
        type_name: "Application/解約",
        method: "post",
        url: "/api/PostCancellation",
        params: arg,
    });
};

export const PostModelInfoChange = (arg) => {
    return AsyncFunction({
        type_name: "Application/登録情報変更",
        method: "post",
        url: "/api/PostModelInfoChange",
        params: arg,
    });
};

export const PostUserChange = (arg) => {
    return AsyncFunction({
        type_name: "Application/使用者変更",
        method: "post",
        url: "/api/PostUserChange",
        params: arg,
    });
};

export const PostAccessoryPurchase = (arg) => {
    return AsyncFunction({
        type_name: "Application/アクセサリ申請",
        method: "post",
        url: "/api/PostAccessoryPurchase",
        params: arg,
    });
};
export const PostDamage = (arg) => {
    return AsyncFunction({
        type_name: "Application/端末修理",
        method: "post",
        url: "/api/PostDamage",
        params: arg,
    });
};
export const PostLoss = (arg) => {
    return AsyncFunction({
        type_name: "Application/紛失",
        method: "post",
        url: "/api/PostLoss",
        params: arg,
    });
};

export const PostReject = (arg) => {
    return AsyncFunction({
        type_name: "Application/否認",
        method: "post",
        url: "/api/PostReject",
        params: arg,
    });
};

export const PostApproval = (arg) => {
    return AsyncFunction({
        type_name: "Application/承認",
        method: "post",
        url: "/api/PostApproval",
        params: arg,
    });
};

export const PostDelete = (arg) => {
    return AsyncFunction({
        type_name: "Application/削除",
        method: "post",
        url: "/api/PostDelete",
        params: arg,
    });
};

export const GetNewMobileApplicationList = (arg) => {
    return AsyncFunction({
        type_name: "NewMobileApplication/新規回線申請一覧の取得",
        method: "post",
        url: "/api/GetNewMobileApplicationList",
        params: arg,
    });
};

export const PostNewMobile = (arg) => {
    return AsyncFunction({
        type_name: "PostNewMobile/新規回線登録",
        method: "post",
        url: "/api/PostNewMobile",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Application",
    initialState,
    reducers: {
        Application_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Application/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.result != "OK") return;
                //退会ユーザー対応
                // if (payload?.model_data && payload?.model_data.length) {
                if (payload?.model_data) {
                    state.model_data = payload?.model_data;
                }
                if (payload?.user_list && payload?.user_list.length) {
                    state.user_list = payload?.user_list;
                }
                if (payload?.application_list) {
                    state.application_list = payload?.application_list;
                }
                if (payload?.approver_list) {
                    state.approver_list = payload?.approver_list;
                }
                if (payload?.company_list) {
                    state.company_list = payload?.company_list;
                }
                if (payload?.department_list) {
                    state.department_list = payload?.department_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Application_set } = slice.actions;
