import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    Label,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
} from "recharts";

import {
    GetAllBillingGuidance,
    AllBillingGuidance_set,
    DounLoadAllBillingGuidance,
    DounLoadAccountCode,
    DounLoadNonTaxable,
    DounLoadTaxable,
    DounLoadAnalysisData
} from "~slices/AllBillingGuidance";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";
import { useHistory } from "react-router";
import { addDays, addMonths, format,subMonths  } from "date-fns";
const AllBillingGuidance = () => {
    const dispatch = useDispatch();

    const ThisState = useSelector((state) => state.AllBillingGuidance);


    const ago_month = (arg) => {
        const day = subMonths(new Date(), 1);
        const format_day = format(day, "yyyy-MM");
        return format_day;
    };

    const [month, setMonth] = React.useState(ago_month);
    const [company, setCompany] = React.useState("");

    const [dev_count, setDevCount] = React.useState(0);
    const [dev_arg, setDevArg] = React.useState("");

    const billing_amount_sum = ThisState.billing_amount_sum;
    const all_sum = ThisState.all_sum;
    const SetCompany = (selected_data) => {
        // console.log(selected_data.company);
        setCompany(selected_data.company);

        dispatch(
            GetAllBillingGuidance({
                company: selected_data.company,
                month: month,
            })
        );
        // dispatch(
        //     SerchCostTrend({
        //         month: month,
        //         company: selected_data.company,
        //         department: selected_data.department,
        //     })
        // );
    };

    const columns = [
        // { label: "電話事業者請求関連合計 ※2", price: 100, tax: 0, nontax: 0 },
        // { label: "通信費支払代行関連合計 ※3", price: 100, tax: 0, nontax: 0 },
        // { label: "通信費小計", price: 100, tax: 0, nontax: 0 },
        // { label: "機器関連合計", price: 100, tax: 0, nontax: 0 },
        // { label: "業務委託関連合計", price: 100, tax: 0, nontax: 0 },
        // { label: "通信費以外小計", price: 100, tax: 0, nontax: 0 },
        // { label: "その他合計 ※4", price: 100, tax: 0, nontax: 0 },
        {
            label: "通信費合計(課税＋非課税)",
            price: Number(ThisState.billing_amount_sum).toLocaleString(),
            tax: 0,
            nontax: 0,
        },
        { label: "通信費合計(非課税)", price: Number(ThisState.non_taxable_sum).toLocaleString(), tax: 0, nontax: 0 },

        {
            label: "消費税等（１０％）",
            price: Number(ThisState.tax_10_sum).toLocaleString(),
            tax: 0,
            nontax: 0,
        },
        {
            label: "手数料(課税)" + "(回線数:" + ThisState.line_count + "×" + "手数料:" + ThisState.commission + ")",
            price: Number(ThisState.commission_sum).toLocaleString(),
            tax: 0,
            nontax: 0,
        },

        {
            label: "御請求金額合計",
            price: Number(all_sum).toLocaleString(),
            tax: 0,
            nontax: 0,
            coler: "#FFCCCC",
        },
    ];

    return (
        <React.Fragment>
            <Typography variant="h4">会社別請求内訳表示</Typography>
            {/* 通信費と通信費以外の請求額のご案内です 会社を選択して下さい */}
            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    type="month"
                    label="請求月"
                    name="month"
                    size="small"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                // {...props}
                />
                <Box sx={{ mt: 1 }}>
                    <SelectUser
                        blancCompany
                        mode="company_only"
                        onSet={(new_list) => SetCompany(new_list)}
                        buttonLabel="検索"
                    />
                </Box>


                {dev_count > 10 ? <>
                    <TextField
                        sx={{ m: 2 }}
                        InputLabelProps={{ shrink: true }}
                        label="開発用キー入力"
                        name="dev_arg"
                        size="small"
                        value={dev_arg}
                        onChange={(e) => setDevArg(e.target.value)}
                    // {...props}
                    />

                </> : <></>}
                <Box sx={{
                    color: "gray", paddingTop: '5px',
                    fontSize: '0.8rem'
                }}>
                    ※会社未選択の検索ですべての会社情報を取得
                </Box>


                {/* <Button onClick={() => HandleSubmit()}>取得</Button> */}
            </Grid>
            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <Box sx={{ m: 2 }}>
                    {/* {ThisState.billing_amount_sum ?
                        <Button
                            onClick={() =>
                                dispatch(
                                    DounLoadAllBillingGuidance({
                                        company: company,
                                        month: month,
                                    })
                                )
                            }
                            variant="contained"
                            size="large"
                            sx={{
                                marginTop: 1, marginRight: '0',
                                marginLeft: 'auto',
                                display: 'block',
                                width: "25rem"
                            }}
                        >
                            会社別請求額csvダウンロード
                        </Button> : ""} */}

                    {/* {ThisState.billing_amount_sum ?
                        <Button
                            onClick={() =>
                                dispatch(
                                    DounLoadAccountCode({
                                        company: company,
                                        month: month,
                                    })
                                )
                            }
                            variant="contained"
                            size="large"
                            sx={{
                                marginTop: 1, marginRight: '0',
                                marginLeft: 'auto',
                                display: 'block',
                                width: "25rem"
                            }}
                        >
                            経費負担コードcsvダウンロード
                        </Button> : ""} */}



                    {/* {ThisState.billing_amount_sum && company ?
                        <Button

                            onClick={() =>
                                dispatch(
                                    DounLoadNonTaxable({
                                        company: company,
                                        month: month,
                                    })
                                )
                            }
                            variant="contained"
                            size="large"
                            sx={{
                                marginTop: 1, marginRight: '0',
                                marginLeft: 'auto',
                                display: 'block',
                                width: "25rem"
                            }}
                        >
                            経費負担コード別非課税csvダウンロード
                        </Button> : ""} */}

                    {/* {ThisState.billing_amount_sum && company ?
                        <Button

                            onClick={() =>
                                dispatch(
                                    DounLoadTaxable({
                                        company: company,
                                        month: month,
                                    })
                                )
                            }
                            variant="contained"
                            size="large"
                            sx={{
                                marginTop: 1, marginRight: '0',
                                marginLeft: 'auto',
                                display: 'block',
                                width: "25rem"
                            }}
                        >
                            経費負担コード別課税csvダウンロード
                        </Button> : ""} */}





                    <Button
                        onClick={() =>
                            dispatch(
                                DounLoadAnalysisData({
                                    company: company,
                                    month: month,
                                    dev_arg: dev_arg,
                                })
                            )
                        }
                        variant="contained"
                        size="large"
                        sx={{
                            marginTop: 1, marginRight: '0',
                            marginLeft: 'auto',
                            display: 'block',
                            width: "25rem"
                        }}
                    >
                        集計データ csvダウンロード
                    </Button>



                </Box>


                <Box sx={{ m: 2 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Box sx={{ p: 1, border: "solid 1px black", width: "150px" }}>ご請求額合計</Box>
                        <Box sx={{ p: 1, border: "solid 1px black", width: "150px", textAlign: "right" }}>
                            ¥{Number(all_sum).toLocaleString()}
                        </Box>
                    </Grid>
                </Box>
                ご請求内訳
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: "#CCCCCC" }}>
                            <TableCell>ご請求項目</TableCell>
                            <TableCell align="right">金額</TableCell>
                            {/* <TableCell align="right">うち消費税</TableCell> */}
                            {/* <TableCell align="right">うち非課税 ※1</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {columns.map((row) => (
                            <TableRow key={row.label} sx={{ background: row?.coler ? row.coler : "none" }}>
                                <TableCell>{row.label}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                {/* <TableCell align="right">¥{row.tax}</TableCell> */}
                                {/* <TableCell align="right">¥{row.nontax}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                    {/* <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell>合計</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">¥{tm_sum_call_fee}</TableCell>
                                </TableRow>
                            </TableBody> */}
                </Table>
            </Grid>

            <Grid
                onClick={() => setDevCount(dev_count + 1)}
                sx={{ width: '100px', height: '100px' }}>


            </Grid>
            {/* <Bikou /> */}
        </React.Fragment>
    );
};

const Bikou = () => {
    return (
        <Box sx={{ m: 1 }}>
            <table cellSpacing={0} cellPadding={0} border={0}>
                <tbody>
                    <tr>
                        <td className="bun12">
                            ※1 「うち非課税」とは、国際電話他のご利用分です。
                            <br />
                            ※2 「電話事業者請求関連」とは、電話事業者より御社口座から直接引き落される請求合計です。
                            <br />
                            ※3
                            「通信費支払代行関連」とは、弊社が電話事業者への支払代行を行い、御社が弊社口座にお振込みいただく請求金額です。
                            <br />
                            ※4 「その他合計」の詳細は、ヘルプデスクまでご確認ください。
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

export default AllBillingGuidance;
