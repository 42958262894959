import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    Label,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
} from "recharts";

import { SerchCostTrend, CostTrend_set } from "~slices/CostTrend";

import { DataGrid, jaJP } from "@mui/x-data-grid";

import SelectUser from "~/components/block/SelectUser";
import { useHistory } from "react-router";

const CostTrend = () => {
    const dispatch = useDispatch();

    const CostTrendState = useSelector((state) => state.CostTrend);

    const [month, setMonth] = React.useState("2023-10");

    const HandleSubmit = (e) => {
        dispatch(SerchBillingStatus());
        // e.preventDefault();
    };

    const SetUserList = (selected_data) => {
        dispatch(
            SerchCostTrend({
                month: month,
                company: selected_data.company,
                department: selected_data.department,
            })
        );
    };

    return (
        <React.Fragment>
            <Typography variant="h4">全体通信費利用推移</Typography>
            部門における移動体通信費の請求推移です
            <Grid sx={{ m: 1, p: 2, border: "solid gray 1px", borderRadius: "0.5rem" }}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    type="month"
                    label="請求月"
                    name="month"
                    size="small"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    // {...props}
                />
                <Box sx={{ mt: 1 }}>
                    <SelectUser mode="company" onSet={(new_list) => SetUserList(new_list)} buttonLabel="検索" />
                </Box>
                {/* <Button onClick={() => HandleSubmit()}>取得</Button> */}
            </Grid>
            {CostTrendState?.cost_trend_list?.length ? (
                <>
                    <Box sx={{ mt: 1, display: "block", textAlign: "-webkit-center" }}>
                        <BarChart width={800} height={250} data={CostTrendState.cost_trend_list}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="billing_month" />
                            <YAxis />
                            <Tooltip
                                formatter={(value, name, props) => [value + "円", "金額"]}
                                // labelFormatter={(value, props) => console.log(props)}
                            />
                            {/* <Legend /> */}
                            <Bar dataKey="total_price" fill="#8884d8" />
                        </BarChart>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>月</TableCell>
                                    <TableCell align="right">金額</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {CostTrendState.cost_trend_list.map((val, index) => (
                                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell scope="row">{val.billing_month}</TableCell>

                                        <TableCell align="right"> {val.total_price.toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                ""
            )}
            {/*
            {BillingStatusState.total_amount ? <Box>合計金額 {BillingStatusState.total_amount}円</Box> : ""} */}
        </React.Fragment>
    );
};

export default CostTrend;
