import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    TextField,
    Grid,
    Container,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { GetApplicationList, Application_set, PostReject, PostApproval, PostDelete } from "~slices/Application";

import { DataGrid, jaJP } from "@mui/x-data-grid";

const ApplicationStatus = () => {


    return (
        <React.Fragment>
            <Typography variant="h4">申請情報表示</Typography>
            申請した情報を確認できます。
            <SearchItemInput />
            <ListTable />
        </React.Fragment>
    );
};

const SearchItemInput = () => {
    const dispatch = useDispatch();

    const InformationState = useSelector((state) => state.Information);

    const [application_type, setApplication_type] = React.useState("");
    const [application_status, setApplication_status] = React.useState("");
    const [applicant_email, setApplicantEmail] = React.useState(InformationState?.user_info[0]?.Address);
    const ApplicationState = useSelector((state) => state.Application);

    const [delete_flag, setDeleteFlag] = React.useState(false);
    const [approver_is_me_flag, setApproverIsMeFlag] = React.useState(true);
    const [approver_email, setApproverEmail] = React.useState(InformationState?.user_info[0]?.Address);



    useEffect(() => {

        const search_item = {
            ...ApplicationState.search_item,
            application_type: application_type,
            application_status: application_status,
            applicant_email: applicant_email,
            delete_flag: delete_flag,
            approver_email: approver_email,
            approver_is_me_flag: approver_is_me_flag,
        };

        dispatch(GetApplicationList(search_item));
    }, []);


    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        // const email = elem?.email?.value;

        const search_item = {
            ...ApplicationState.search_item,
            application_type: application_type,
            application_status: application_status,
            applicant_email: applicant_email,
            delete_flag: delete_flag,
            approver_email: approver_email,
            approver_is_me_flag: approver_is_me_flag,
        };

        dispatch(Application_set(["search_item", search_item]));
        dispatch(GetApplicationList(search_item));
    };

    const application_status_list = ["", "承認待ち", "申請中", "申請完了", "否認", "完了"];
    const application_type_list = ["", "新規登録", "機種変更", "解約", "登録情報変更", "使用者変更", "アクセサリ購入", '端末修理', '紛失'];

    return (
        <React.Fragment>
            <form onSubmit={HandleSubmit}>
                <Box
                    sx={{
                        width: "100%",
                        mt: "1rem",
                        display: "grid",
                    }}
                >

                    <Box sx={{
                        border: "solid 1px black",
                        pt: 1,
                        pl: 1,
                        pr: 1,
                        mb: 1
                    }}>
                        <p>※各申請の詳細ボタンから承認を行うことが出来ます。</p>
                        <p>※各申請の右端に詳細ボタンが表示されていない方はブラウザの表示倍率を小さくしてください。</p>
                    </Box>
                    <FormControl sx={{ mb: "1rem", width: "20rem" }}>
                        <InputLabel id="select-application_type">申請区分</InputLabel>
                        <Select
                            value={application_type}
                            fullWidth
                            label="申請区分"
                            name="model"
                            onChange={(e) => setApplication_type(e.target.value)}
                        >
                            {application_type_list.map((application_type, index) => (
                                <MenuItem value={application_type} key={index}>
                                    {application_type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ mb: "1rem", width: "20rem" }}>
                        <InputLabel id="select-application_status">申請状況</InputLabel>
                        <Select
                            value={application_status}
                            fullWidth
                            label="申請状況"
                            name="model"
                            onChange={(e) => setApplication_status(e.target.value)}
                        >
                            {application_status_list.map((application_status, index) => (
                                <MenuItem value={application_status} key={index}>
                                    {application_status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{ mb: 1 }}>
                        <TextField
                            sx={{ width: "20rem" }}
                            value={applicant_email}
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="申請者メールアドレス"
                            name="applicant_email"
                            onChange={(e) => setApplicantEmail(e.target.value)}
                        />{" "}
                    </Box>

                    {/* <Box sx={{ mb: 1 }}>
                        <TextField
                            sx={{ width: "20rem" }}
                            value={approver_email}
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="承認者メールアドレス"
                            name="applicant_email"
                            onChange={(e) => setApproverEmail(e.target.value)}
                        />{" "}
                    </Box> */}

                    <FormControlLabel
                        control={<Checkbox checked={delete_flag} onChange={(e) => setDeleteFlag(e.target.checked)} />}
                        label="削除した申請を確認する"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={approver_is_me_flag}
                                onChange={(e) => setApproverIsMeFlag(e.target.checked)}
                            />
                        }
                        label="自分が承認者の申請を表示する"
                    />

                    <Button variant="contained" color="primary" type="submit">
                        検索
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
};

const ListTable = (props) => {
    const ApplicationState = useSelector((state) => state.Application);

    const InformationState = useSelector((state) => state.Information);

    const dispatch = useDispatch();
    const [selected_application, setSelecteApplication] = React.useState(false);
    const [reject_reason, setRejectReason] = React.useState("");
    const HandleClick = (row) => {
        setRejectReason(row.reject_reason);
        setSelecteApplication(row);
    };

    const popup_columns = [
        { headerName: "申請ID", field: "application_id" },
        { headerName: "申請区分", field: "application_type" },
        { headerName: "電話番号", field: "tel" },
        { headerName: "申請者メールアドレス", field: "applicant_email" },
        { headerName: "申請者名", field: "applicant" },
        { headerName: "使用者名", field: "user_name" },
        { headerName: "使用者メールアドレス", field: "user_email" },
        { headerName: "使用者部署名", field: "user_department" },
        { headerName: "使用者会社名", field: "user_company" },
        { headerName: "機種", field: "model" },
        { headerName: "申請理由/特記事項", field: "remarks" },
        { headerName: "用途", field: "usage" },
        { headerName: "使用者種別", field: "user_type" },
        { headerName: "経費負担コード", field: "cost_code" },
        { headerName: "勘定コード", field: "account_code" },
        { headerName: "送付先名", field: "sending_name" },
        { headerName: "送付先郵便番号", field: "sending_post_code" },
        { headerName: "送付先住所", field: "sending_address" },
        { headerName: "送付先電話番号", field: "sending_tel" },
        { headerName: "使用者変更フラグ", field: "change_user_flag" },
        { headerName: "変更後利用者メールアドレス", field: "change_email" },
        { headerName: "変更後利用者名", field: "change_name" },
        { headerName: "変更後利用者会社", field: "change_company" },
        { headerName: "変更後利用者部署", field: "change_department" },
        { headerName: "変更後使用者種別", field: "change_user_type" },
        { headerName: "経費負担コード変更フラグ", field: "change_cost_code_flag" },
        { headerName: "希望月", field: "hope_date" },
        { headerName: "購入アクセサリID", field: "accessory_id" },
        { headerName: "購入アクセサリ名", field: "accessory_name" },
        { headerName: "端末修理内容", field: "broken_type" },
        { headerName: "端末修理状況", field: "broken_detail" },
        { headerName: "紛失内容", field: "loss_type" },
        { headerName: "紛失状況", field: "loss_detail" },
        { headerName: "紛失日", field: "loss_date" },
        { headerName: "申請日", field: "created_at" },
        { headerName: "承認者", field: "approver_name" },
        { headerName: "承認者メールアドレス", field: "approver_email" },
    ];

    const typeView = (application_type) => {
        let color = "default";
        switch (application_type) {
            // case "新規登録":
            // color = "info";
            // break;
            // case "登録情報変更":
            //     color = "success";
            //     break;
            // case "解約":
            //     color = "error";
            //     break;

            default:
                return application_type;
                break;
        }
        return <Chip color={color} label={application_type} />;
    };

    const isMe = (name) => {
        let color = "default";
        switch (name) {
            case InformationState.user_info[0]?.DisplayName:
                color = "info";
                break;
            default:
                return name;
        }

        // return <Chip color={color} label={name} />;
        return <Typography sx={{ color: "#0026ff" }}>{name}</Typography>;
    };

    const columns = [
        { headerName: "申請ID", field: "application_id", width: 110 },
        { headerName: "申請日", field: "created_at",    width: 130, },
        {
            headerName: "申請区分",
            field: "application_type",
            width: 130,
            renderCell: (params) => <Box>{typeView(params.row.application_type)}</Box>,
        },
        { headerName: "電話番号", field: "tel", width: 150 },
        { headerName: "申請者メールアドレス", field: "applicant_email", width: 250 },
        {
            headerName: "申請者名",
            field: "applicant",
            width: 150,
            renderCell: (params) => <Box>{isMe(params.row?.applicant)}</Box>,
        },
        { headerName: "申請状況", field: "application_status" },
        // { headerName: "使用者名", field: "user_name" },
        // { headerName: "使用者メールアドレス", field: "user_email" },
        // { headerName: "使用者部署名", field: "user_department" },
        // { headerName: "使用者会社名", field: "user_company" },
        // { headerName: "機種", field: "model" },
        // { headerName: "申請理由/特記事項", field: "remarks", flex: true },
        // { headerName: "用途", field: "usage" },
        // { headerName: "使用者種別", field: "user_type" },
        // { headerName: "経費負担コード", field: "cost_code" },
        // { headerName: "勘定コード", field: "account_code" },
        // { headerName: "送付先名", field: "sending_name" },
        // { headerName: "送付先郵便番号", field: "sending_post_code" },
        // { headerName: "送付先住所", field: "sending_address" },
        // { headerName: "送付先電話番号", field: "sending_tel" },
        // { headerName: "使用者変更フラグ", field: "change_user_flag" },
        // { headerName: "変更後利用者メールアドレス", field: "change_email" },
        // { headerName: "変更後利用者名", field: "change_name" },
        // { headerName: "変更後利用者会社", field: "change_company" },
        // { headerName: "変更後利用者部署", field: "change_department" },
        // { headerName: "変更後使用者種別", field: "change_user_type" },
        // { headerName: "経費負担コード変更フラグ", field: "change_cost_code_flag" },
        // { headerName: "希望日", field: "hope_date" },
        // { headerName: "購入アクセサリID", field: "accessory_id" },
        // { headerName: "購入アクセサリ名", field: "accessory_name" },
        // { headerName: "端末修理内容", field: "broken_type" },
        // { headerName: "端末修理状況", field: "broken_detail" },
        // { headerName: "紛失内容", field: "loss_type" },
        // { headerName: "紛失状況", field: "loss_detail" },
        // { headerName: "紛失日", field: "loss_date" },

        {
            headerName: "承認者",
            field: "approver_name",
            width: 130,
            renderCell: (params) => <Box>{isMe(params.row?.approver_name)}</Box>,
        },

        {
            headerName: "詳細",

            field: "detail",
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => HandleClick(params.row)}>
                    詳細
                </Button>
            ),
        },
    ];

    const handleReject = () => {
        dispatch(
            PostReject({
                application_id: selected_application.application_id,
                reject_reason: reject_reason,
            })
        ).then((data) => {
            setSelecteApplication(false);
            dispatch(GetApplicationList(ApplicationState.search_item));
        });
    };

    const handleApproval = () => {
        dispatch(
            PostApproval({
                application_id: selected_application.application_id,
            })
        ).then((data) => {
            setSelecteApplication(false);
            dispatch(GetApplicationList(ApplicationState.search_item));
        });
    };

    const handleDelete = () => {
        dispatch(
            PostDelete({
                application_id: selected_application.application_id,
            })
        ).then((data) => {
            setSelecteApplication(false);
            dispatch(GetApplicationList(ApplicationState.search_item));
        });
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <Dialog
                    open={selected_application == false ? false : true}
                    onClose={() => setSelecteApplication(false)}
                >
                    <DialogTitle sx={{ m: 0, p: 2, background: "deepskyblue" }}> 申請詳細</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setSelecteApplication(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#454545",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers sx={{ minWidth: "500px", minHeight: "300px", overflow: "hidden" }}>
                        <Box
                            sx={{
                                // minWidth: "40rem",
                                width: "100%",
                                // mt: "1rem", // minWidth: "100%"
                                display: "grid",
                            }}
                        >
                            <Table>
                                <TableBody>
                                    {popup_columns.map((val, i) => (
                                        <React.Fragment key={i}>
                                            {selected_application[val.field] ? (
                                                <TableRow>
                                                    <TableCell sx={{ minWidth: "12rem" }}>{val.headerName}</TableCell>
                                                    <TableCell> {selected_application[val.field]}</TableCell>
                                                </TableRow>
                                            ) : (
                                                <></>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>

                        <Box
                            sx={{
                                mt: "1rem",
                                // p: 1,
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="否認理由"
                                name="reject_reason"
                                value={reject_reason}
                                onChange={(e) => setRejectReason(e.target.value)}
                                multiline
                                rows={2}
                                fullWidth
                                helperText="※否認の場合のみ記入してください。"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ marginRight: "auto", marginLeft: "0" }}
                            onClick={handleDelete}
                        >
                            削除
                        </Button>

                        {/* 承認者のみ */}
                        {InformationState?.user_info[0]?.Address == selected_application?.approver_email ? (
                            <>
                                {selected_application?.application_status == "承認待ち" ||
                                    selected_application?.application_status == "否認" ? (
                                    <>
                                        <Button onClick={handleReject}>否認</Button>
                                        <Button variant="contained" onClick={handleApproval} autoFocus>
                                            承認
                                        </Button>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <Box
                sx={{
                    minHeight: 900,
                    width: "100%",
                    mt: "1rem", // minWidth: "100%"
                    display: "grid",
                }}
            >
                <DataGrid
                    rows={ApplicationState.application_list}
                    columns={columns}
                    disableCellSelectionOnClick
                    disableSelectionOnClick
                    pagination
                    // onRowClick={(e) => HandleRowClick(e.row)}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </React.Fragment>
    );
};

export default ApplicationStatus;
