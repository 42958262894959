import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { initialize, Upload, UploadCSV_set, GetCSVNameList } from "~slices/UploadCSV";

import { Progress_set } from "~slices/Progress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Modal,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Chip,
    LinearProgress,
    Input,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { parse } from "papaparse";
import Encoding from "encoding-japanese";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const CheckFileName = (file_name) => {
    if (file_name.startsWith("SU") || file_name.startsWith("TM") || file_name.startsWith("HABE")) {
        return true;
    } else {
        return false;
    }
};
const UploadCSV = () => {
    const dispatch = useDispatch();
    const UploadCSVState = useSelector((state) => state.UploadCSV);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetCSVNameList());
    }, []);

    const InputCSV = (e) => {
        const target_files = e.target.files;
        const files = Array.from(target_files);

        dispatch(UploadCSV_set(["name_list", {}]));
        dispatch(UploadCSV_set(["error_message", ""]));
        // csv_data

        const name_list = files.map((v, i) => {
            if (CheckFileName(v.name)) {
                // dispatch(UploadCSV_set(["loading", true]));
                return v.name;
            } else {
                dispatch(
                    UploadCSV_set(["error_message", "SUまたはTMまたはHABEから始まるファイル以外は読み込めません"])
                );
                return v.name;
            }
        });
        dispatch(UploadCSV_set(["name_list", name_list]));
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const files = Array.from(elem.csv.files);

        const data = new FormData();
        files.map((file) => {
            data.append("file[]", file);
        });
        // console.log("████████data█████████████████████████████");
        // console.log(data);
        // data.append("test_text", "これはテストテキストです。");

        dispatch(Upload(data));
    };

    const tableTitle = {
        background: "deepskyblue",
        padding: "3px",
        borderRadius: "4px 4px 0px 0px",
    };
    return (
        <React.Fragment>
            <Typography variant="h4">データアップロード</Typography>
            SUとTMの通信データまたは、HABEから始まる人事データをインポートができます。
            <Stack sx={{ pb: "1rem" }}>
                <form onSubmit={(e) => HandleSubmit(e)} id="form1">
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        CSVファイルを選択
                        <VisuallyHiddenInput
                            type="file"
                            id="csv"
                            name="file[]"
                            accept=".csv"
                            multiple
                            onChange={(e) => InputCSV(e)}
                            sx={{ m: 1 }}
                        />
                    </Button>

                    {UploadCSVState.loading && <LinearProgress sx={{ m: "1rem" }} />}

                    {UploadCSVState.error_message && (
                        <Typography display="block" sx={{ color: "red" }}>
                            {UploadCSVState.error_message}
                        </Typography>
                    )}
                </form>
            </Stack>
            <Stack spacing={1} sx={{ m: 1 }}>
                {UploadCSVState.name_list &&
                    UploadCSVState.name_list.map((name, i) => (
                        <React.Fragment key={i}>
                            <Chip
                                label={name}
                                color={CheckFileName(name) ? "success" : "error"}
                                onClick={() => dispatch(UploadCSV_set(["selected", name]))}
                            />
                        </React.Fragment>
                    ))}
            </Stack>
            <Stack spacing={1} sx={{ m: 1 }}>
                <Button
                    form="form1"
                    variant="contained"
                    type="submit"
                    disabled={!UploadCSVState.name_list.length || UploadCSVState.error_message !== ""}
                >
                    アップロード
                </Button>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ m: 1, mt: 2 }}>
                <Button variant="contained" onClick={() => dispatch(GetCSVNameList())}>
                    更新
                </Button>
            </Stack>
            <Typography sx={tableTitle}>処理状況</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ファイル名</TableCell>
                            <TableCell>処理開始</TableCell>
                            <TableCell>状況</TableCell>
                            <TableCell>メッセージ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {UploadCSVState?.process_list.map((val, i) => (
                            <React.Fragment key={i}>
                                <TableRow>
                                    <TableCell>{val.process_name}</TableCell>
                                    <TableCell>{val.created_at}</TableCell>
                                    <TableCell>{val.end_flag ? "終了" : "処理中"}</TableCell>
                                    <TableCell>{val.option1}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                spacing={{ xs: 2, md: 3 }}
                sx={{ mt: 2 }}
            >
                <Grid item xs={6}>
                    <Typography sx={tableTitle}>SUファイル</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ファイル名</TableCell>
                                    <TableCell>請求年月</TableCell>
                                    <TableCell>件数</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UploadCSVState?.su_csv_list.map((val, i) => (
                                    <React.Fragment key={i}>
                                        <TableRow>
                                            <TableCell>{val.file_name}</TableCell>
                                            <TableCell>{val.billing_month}</TableCell>
                                            <TableCell>{val.count}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={tableTitle}>TMファイル</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ファイル名</TableCell>
                                    <TableCell>請求年月</TableCell>
                                    <TableCell>件数</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UploadCSVState?.tm_csv_list.map((val, i) => (
                                    <React.Fragment key={i}>
                                        <TableRow>
                                            <TableCell>{val.file_name}</TableCell>
                                            <TableCell>{val.billing_year_month}</TableCell>
                                            <TableCell>{val.count}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default UploadCSV;
