import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import { top_menu_data } from "~/components/const/top_menu_data";
import { admin_menu_data } from "~/components/const/admin_menu_data";

import NaviTree from "~/components/block/NaviTree";
import { UserLogout } from "~slices/Information";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function NaviDrawer(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);
    // React.useLayoutEffect(() => {
    //     const screen_width = window.screen.width;
    //     if (screen_width > 2000) {
    //         setOpen(true);
    //     }
    // }, []);

    //==============================================================
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    };
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useLayoutEffect(() => {
        const screen_width = windowDimensions.width;
        if (screen_width > 1000) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [windowDimensions]);

    React.useEffect(() => {
        const onResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    //==============================================================

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    let links = [
        { label: "トップ", link: "/", icon: <MailIcon /> },
        { label: "新規登録", link: "/signup", icon: <MailIcon /> },
        { label: "ログイン", link: "/login", icon: <MailIcon /> },
    ];

    const LinkList = () => (
        <Box role="presentation">
            <List>
                {links.map((link_item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton component={Link} to={link_item?.link}>
                            <ListItemIcon>{link_item?.icon} </ListItemIcon>
                            <ListItemText primary={link_item?.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    const submenu = {
        background: "darkorange",
        margin: "0.5rem 0.5rem 0rem 0.5rem",
        padding: "1rem",
        borderRadius: "0.5rem",
        fontWeight: "bold",
        border: "solid 1px",

        "&:hover": {
            backgroundColor: "#d55600",
            letterSpacing: "0.15em",
        },
        // "&:after": {
        //     background: "#ff701e",
        //     position: "absolute",
        //     top: "0",
        //     left: "0",
        //     content: "''",
        //     width: "100%",
        //     height: "100%",
        //     transform: "scale(0, 1)",
        //     transformOrigin: "left top",
        //     transition: ".2s cubic-bezier(0.45, 0, 0.55, 1)",
        //     zIndex: "-1",
        // },

        // "&hover::after": {
        //     transform: "scale(1, 1)",
        // },
    };

    const handleLogout = () => {
        dispatch(UserLogout());
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to={"/"} >
                        <img src="/images/logo/png_4.png" height={"50px"} />
                        {/* <div style={{ color: "white" }}> TelSystem</div> */}
                    </Link>

                    {InformationState.login_status ? (
                        <>
                            <Button
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "auto",
                                    color: "white",
                                    fontSize: "1.2rem",
                                    textDecoration: "none",
                                }}
                                onClick={() => handleLogout()}
                            >
                                ログアウト
                            </Button>
                        </>
                    ) : (
                        <Link
                            style={{
                                marginRight: "0px",
                                marginLeft: "auto",
                                color: "white",
                                fontSize: "1.2rem",
                                textDecoration: "none",
                            }}
                            to={"/Login"}
                        >
                            ログイン
                        </Link>
                    )}
                </Toolbar>
                {/* <Stack direction="row" justifyContent="space-between" alignItems="flex-start"> */}

                {/* </Stack> */}
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {/* <Box sx={submenu}>請求情報</Box>
                <Box sx={submenu}>レポート情報</Box>
                <Box sx={submenu}>各種ダウンロード</Box> */}

                {/* <LinkList /> */}

                {InformationState.login_status ? (
                    <>
                        {/* <Box sx={{ m: "0.5rem", borderRadius: "0.5rem", border: "solid 1px" }}> */}
                        <Box>
                            <NaviTree menuData={top_menu_data} />
                        </Box>
                        {/* </Box> */}

                        {InformationState?.user_type == "admin" ? (
                            <>
                                {/* <Box sx={{ m: "0.5rem", borderRadius: "0.5rem", border: "solid 1px" }}> */}
                                <Box>
                                    {/* <NaviTree />*/}
                                    <Box sx={{ background: "deepskyblue", padding: "0.3rem", marginTop: "1rem" }}>
                                        管理機能
                                    </Box>
                                    <NaviTree menuData={admin_menu_data} />
                                </Box>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    ""
                )}
                {/* <List>
                    {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List> */}
            </Drawer>
            <Main open={open} id="main">
                <DrawerHeader />

                {props.children}
            </Main>
        </Box>
    );
}
